import "./Home.scss";
// import AOS
import "aos/dist/aos.css";

import Hero from "../../Hero-element/Hero";
import Cars from "../../Cars/Cars";
import Sponsors from "../../Sponsors/Sponsors";
import TeamScroll from "../../Team-scroll/TeamScroll";
import SmallAboutUs from "../AboutUs/SmallAboutUs";

function Home() {
  return (
    <>
      <Hero />
      <SmallAboutUs />
      <TeamScroll />
      <Cars />
      <Sponsors />
    </>
  );
}

export default Home;
