import "./Navbar.scss";
import React, {useState} from "react";
import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import NavDropdown from "react-bootstrap/NavDropdown";
import logo from "../../Resources/Pics/tdsr-full-logo-green.svg";
import Button from "react-bootstrap/Button";
import { HashLink } from "react-router-hash-link";

function Navbar_blurred() {
  const [expanded, setExpanded] = useState(false); // Control navbar expand state

  const handleToggle = () => setExpanded(!expanded);
  const closeNavbar = () => setExpanded(false);
  return (
    <Navbar collapseOnSelect={true} sticky="top" className="blurry" expand="xl" expanded={expanded}>
      <Container>
        <Navbar.Brand href="/">
          <img
            src={logo}
            width="120"
            height="40"
            className="d-inline-block align-top"
            alt="TDSR logo"
          />
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav ml-auto" onClick={handleToggle}/>
        <Navbar.Collapse>
          <Nav className="ml-auto align-it">
            <Nav.Link className="main" href="/about" onClick={closeNavbar}>
              About Us
            </Nav.Link>
            <NavDropdown className="main" title="Teams" id="basic-nav-dropdown">
              <NavDropdown.Item href="" onClick={closeNavbar}>
                <HashLink className="dropItem" to="/team25">
                  Team 25
                </HashLink>
              </NavDropdown.Item>
              <NavDropdown.Item href="" onClick={closeNavbar}>
                <HashLink className="dropItem" to="/team23">
                  Team 23{" "}
                </HashLink>
              </NavDropdown.Item>
              <NavDropdown.Item href="" onClick={closeNavbar}>
                <HashLink className="dropItem" to="/team21">
                  Team 21{" "}
                </HashLink>
              </NavDropdown.Item>
              <NavDropdown.Item href="" onClick={closeNavbar}>
                <HashLink className="dropItem" to="/team19">
                  Team 19{" "}
                </HashLink>
              </NavDropdown.Item>
            </NavDropdown>

            <NavDropdown
              className="main"
              title="Challenges"
              id="basic-nav-dropdown"
            >
              <NavDropdown.Item href="" onClick={closeNavbar}>
                <HashLink className="dropItem" to="/challenges#iESC24">
                  2024 iLumen European Solar Challenge
                </HashLink>
              </NavDropdown.Item>
              <NavDropdown.Item href="" onClick={closeNavbar}>
                <HashLink className="dropItem" to="/challenges#Australia23">
                  2023 Bridgestone World Solar Challenge
                </HashLink>
              </NavDropdown.Item>
              <NavDropdown.Item href="" onClick={closeNavbar}>
                <HashLink className="dropItem" to="/challenges#iESC22">
                  2022 iLumen European Solar Challenge
                </HashLink>
              </NavDropdown.Item>
              <NavDropdown.Item href="" onClick={closeNavbar}>
                <HashLink className="dropItem" to="/challenges#Morocco">
                  2021 Moroccan Solar Challenge
                </HashLink>
              </NavDropdown.Item>
              <NavDropdown.Item href="" onClick={closeNavbar}>
                <HashLink className="dropItem" to="/challenges#iESC20">
                  2020 iLumen European Solar Challenge
                </HashLink>
              </NavDropdown.Item>
              <NavDropdown.Item href="" onClick={closeNavbar}>
                <HashLink className="dropItem" to="/challenges#Australia19">
                  2019 Bridgestone World Solar Challenge
                </HashLink>
              </NavDropdown.Item>
            </NavDropdown>
            <NavDropdown className="main" title="Cars" id="basic-nav-dropdown">
              <NavDropdown.Item href="" onClick={closeNavbar}>
                <HashLink className="dropItem" to="/green-thunder">
                  Green Thunder
                </HashLink>
              </NavDropdown.Item>
              <NavDropdown.Item href="" onClick={closeNavbar}>
                <HashLink className="dropItem" to="/green-spirit">
                  Green Spirit
                </HashLink>
              </NavDropdown.Item>
              <NavDropdown.Item href="" onClick={closeNavbar}>
                <HashLink className="dropItem" to="/green-lightning">
                  Green Lightning
                </HashLink>
              </NavDropdown.Item>
            </NavDropdown>
            <Nav.Link className="main" href="https://www.solarblogs.nl/" onClick={closeNavbar}>
              Blogs
            </Nav.Link>
            <Nav.Link className="main" href="/partners" onClick={closeNavbar}>
              Partners
            </Nav.Link>
            <Nav.Link className="main" href="/contact" onClick={closeNavbar}>
              Contact
            </Nav.Link>

            {/* <Nav.Link className="main" href="/hydrogen">
              <Button className="hydrogen">Hydrogen Team!</Button>{" "}
            </Nav.Link> */}

            {/* <Nav.Link className="main" href="http://roadtovictory.nl">
              <Button className="button-menu">Road to Victory</Button>{" "}
            </Nav.Link> */}
            <Nav.Link className="main" href="http://eepurl.com/dNAm4U" onClick={closeNavbar}>
              <Button className="button-menu"> Newsletter </Button>{" "}
            </Nav.Link>
            <Nav.Link className="main" href="/become" onClick={closeNavbar}>
              <Button className="button-menu"> Become part of the Team </Button>{" "}
            </Nav.Link>
            <Nav.Link className="main" href="/faq" onClick={closeNavbar}>
              <Button className="button-menu"> FAQ Section </Button>{" "}
            </Nav.Link>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
}

export default Navbar_blurred;
