import React from "react";

import imgMorocco from "../../Resources/Pics/challenges/imgMorocco.webp";
import imgBWSC from "../../Resources/Pics/challenges/imgBWSC.webp";
import imgBWSC23 from "../../Resources/Pics/challenges/imgBWSC23.webp";
import imgIESC22 from "../../Resources/Pics/challenges/imgIESC22.webp";
import imgIESC20 from "../../Resources/Pics/challenges/imgIESC20.webp";
import imgIESC24 from "../../Resources/Pics/challenges/imgIESC24.webp";

let linkIESC22 = "https://www.youtube.com/watch?v=W7xF7Cq42t8";

const Morocco = () => {
  const duration = 1000;
  return (
    <div
      id="Morocco"
      // data-aos="fade-left"
      // data-aos-duration={duration}
      className="container simple-section mt-5 mb-5"
    >
      <div className="row simple-section-header">
        <h1>2021 Moroccan Solar Challenge</h1>
      </div>
      <div className="row simple-section-body">
        <p>
          When the Bridgestone World Solar Challenge was cancelled in February
          2021, we had to come up with an alternative race to showcase our new
          solar car, Green Spirit, and compete with solar teams from around the
          world. The result of months of consulting, meeting with other teams
          and hard work is the Solar Challenge Morocco 2021.
        </p>
        <p className="mb-5">
          This new race lead us 2500 km through the Middle-Atlas and Sahara
          desert. Since the race was held for the very first time in 2021, all
          teams had the same chances of crossing the finish line first. The
          Moroccan Solar Challenge was a time challenge divided over 5 different
          stages and lasted 5 days in total. The Solar Challenge Morocco both
          started and ended in the port city Agadir.
        </p>
        <img src={imgMorocco} alt="img-morocco" loading="lazy" />
        <p className="mt-5">
          At first glance, the Solar Challenge Morocco and Bridgestone World
          Solar Challenge look quite similar; after all, the climate in
          Australia and Morocco is comparable. However, there are a number of
          important differences that make the Solar Challenge Morocco a
          completely new challenge for Top Dutch Solar Racing. First, the
          landscape in Morocco is very mountainous. The result is that the race
          route has a height difference of approximately 8 km and a 10 degree
          climbing slope. This was a challenge for our solar car, as it was
          engineering was based on the Australian Outback countryside. As such,
          for Morocco, the electric motor was adapted to cope with the
          mountainous landscape.
        </p>
        <p>
          In addition, the race route of the Solar Challenge Morocco was not a
          straight line like the route from Darwin to Adelaide. The roads in
          Morocco have many curves and are not all equally good. Therefore, the
          driving skills and competence of our drivers was very important during
          this race. They needed to remain focused at all times - especially on
          the narrow mountain roads in the Atlas!
        </p>
      </div>
    </div>
  );
};

const Australia19 = () => {
  const duration = 1000;
  return (
    <div
      id="Australia19"
      // data-aos="fade-left"
      // data-aos-duration={duration}
      className="container simple-section mt-5 mb-5"
    >
      <div className="row simple-section-header">
        <h1>2019 Bridgestone World Solar Challenge</h1>
      </div>
      <div className="row simple-section-body">
        <p>
          In 2019, we participated for the very first time in the World Solar
          Challenge. It was an awesome experience in which we've learned a lot.
          Our most precious moments? Camping in the beautiful Australian
          outback. The energy of our team during the race. The moment we
          finished with Green Lightning in Adelaide. And the fact we made it,
          together.
        </p>
        <p className="mb-5">
          With a brand new solar car, we took on the challenge to drive through
          the middle of Australia, from Darwin to Adelaide in 5 days. During the
          race, we could start driving at 8:00 and we had to stop at 17:00.
          During the day we encountered pit stops, where the team had a
          mandatory stop of 30 minutes in which no one was allowed to touch the
          solar car. At 17:00 we had to stop wherever we were situated, meaning
          we had to set up camp in the middle of nowhere and prepare for the
          next racing day.
        </p>
        <img src={imgBWSC} alt="img-morocco" loading="lazy" />
        <p className="mt-5">
          Participating in The Bridgestone World Solar Challenge took more than
          technical innovations, outside the box thinking and designing the
          perfect parts. It involved commitment, patience, ambition,
          perseverance, entrepreneurship and a lot of time. Not only did we need
          to build the perfect solar car, but we also needed to organize an
          entire travel for 25 people to Australia for two months; we needed to
          find the right partners to work with; we needed to make sure everyone
          could work together at full pace and we needed to make sure our
          drivers are fully prepared for our challenge.
        </p>
      </div>
    </div>
  );
};

const IESC22 = () => {
  const duration = 1000;
  return (
    <div
      id="iESC22"
      // data-aos="fade-right"
      // data-aos-duration={duration}
      className="container simple-section mt-5 mb-5"
    >
      <div className="row simple-section-header">
        <h1>2022 iLumen European Solar Challenge</h1>
      </div>
      <div className="row simple-section-body">
        <p className="mb-5">
          In 2022 we participated at the iESC for the second time. In
          cooperation with some memebers of the Team 2019 and 2021 we were able
          to drive the Green Lightning to the 5th place! This was an amazing
          experience for us, Team 2023, to gain experience and a lot of learning
          outcomes for future challenges.
        </p>
        <img src={imgIESC22} alt="img-morocco" loading="lazy" />
        <p className="mt-5">
          Take a look at our{" "}
          <a href={linkIESC22} target="_blank" rel="noreferrer" className="link-to-page">
            aftermovie
          </a>{" "}
          to see how we experienced the iESC.
        </p>
      </div>
    </div>
  );
};

const IESC20 = () => {
  const duration = 1000;
  return (
    <div
      id="iESC20"
      // data-aos="fade-right"
      // data-aos-duration={duration}
      className="container simple-section mt-5 mb-5"
    >
      <div className="row simple-section-header">
        <h1>2020 iLumen European Solar Challenge</h1>
      </div>
      <div className="row simple-section-body">
        <p>
          The iLumen European Solar Challenge is being held every two years, on
          Circuit Zolder in Belgium. The Challenge is a 24-hour endurance race
          in which teams can participate with a maximum of two solar cars. As
          the organisation says: "At the race track, the solar cars can show
          their full potential with low power consumption and fantastic
          aerodynamics over a full day."
        </p>
        <p className="mb-5">
          Circuit Zolder is a former Formula 1 circuit and is located in
          Heusden-Zolder in Belgium. It was built in 1963. The track is four
          kilometers long, has ten corners of which three chicanes, and two long
          straights. With the Sacramentsheuvel laying in between chicane 5 and
          6, this track is full of challenges. Not only does the circuit host
          (solar) car races, they also host bicycle, motorcycle and running
          races on the track.
        </p>
        <img src={imgIESC20} alt="img-morocco" loading="lazy" />
        <p className="mt-5">
          During the 2020 edition of the European Solar Challenge, we
          participated for the first time! In cooperation with Team 2019, we
          managed to win third place!
        </p>
      </div>
    </div>
  );
};

const IESC24 = () => {
  const duration = 1000;
  return (
    <div
      id="iESC24"
      // data-aos="fade-right"
      // data-aos-duration={duration}
      className="container simple-section mt-5 mb-5"
    >
      <div className="row simple-section-header">
        <h1>2024 iLumen European Solar Challenge</h1>
      </div>
      <div className="row simple-section-body">
        <p>
          Team 2025 faced their first challenge at the iLumen European Solar
          Challenge (iESC), and with incredible determination and high
          motivation, they delivered outstanding results. Alongside the 27 team
          members came our 12 alumni from Team '23. Combining all of our
          expertise and curiosity to gain our National Champion title as well as
          Green Thunder ending as 4th in Europe, including 3rd Team in Europe.
        </p>
        <p className="mb-5">
          Heavily credited to our speed, not only on the tracks but in the pit
          box. Due to fastest hot lap and fastest pit-box we got Pole Position
          from qualifying, leading to us to push again in the race. Getting the
          fastest hot lap during the 24hr race itself! This achievement reflects
          the team's perseverance and passion. Pushing through challenges before
          and during the race, bringing our team together. Creating a fortified
          racing community of passionate individuals.
        </p>
        <img src={imgIESC24} alt="img-iesc24" loading="lazy" />
        <p className="mt-5">
          We are excited to build on this momentum for future challenges!
          Waiting to prove to the world that Top Dutch is one of the best solar
          racing teams out there! Take a look at our Instagram to see how we
          experienced the iESC.
        </p>
      </div>
    </div>
  );
};

const Australia23 = () => {
  const duration = 1000;
  return (
    <div
      id="Australia23"
      // data-aos="fade-left"
      // data-aos-duration={duration}
      className="container simple-section mt-5 mb-5"
    >
      <div className="row simple-section-header">
        <h1>2023 Bridgestone World Solar Challenge</h1>
      </div>
      <div className="row simple-section-body">
        <p>
          Team 2023 embarked on their first major race at the Bridgestone World
          Solar Challenge in Australia. With unwavering determination and
          innovative technology, the team delivered out- standing results,
          securing 6th place internationally and 4th in qualifiers.
        </p>
        <p className="mb-5">
          This incredible achievement is a testament to the team's resilience,
          technical expertise, and passion for sustainable energy. We are
          excited to build on this momentum as we look forward to future
          challenges!
        </p>
        <img src={imgBWSC23} alt="img-morocco" loading="lazy" />
      </div>
    </div>
  );
};

function Challenges() {
  return (
    <div className="challenges">
      <IESC24 />
      <Australia23 />
      <IESC22 />
      <Morocco />
      <IESC20 />
      <Australia19 />
    </div>
  );
}

export default Challenges;
