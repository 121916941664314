import "./TeamScroll.scss";
// import AOS
import "aos/dist/aos.css";
import { Link } from "react-router-dom";
import { useState, useEffect } from "react";

// photos import
import Willy from "../Resources/Pics/team_photos_25_webp/willy.webp";
import Tom from "../Resources/Pics/team_photos_25_webp/tom.webp";
import Julliette from "../Resources/Pics/team_photos_25_webp/julliette.webp";
import Daan from "../Resources/Pics/team_photos_25_webp/daan.webp";
import Egbert from "../Resources/Pics/team_photos_25_webp/egbert.webp";
import Etjen from "../Resources/Pics/team_photos_25_webp/etjen.webp";
import Jort from "../Resources/Pics/team_photos_25_webp/jort.webp";
import Mariza from "../Resources/Pics/team_photos_25_webp/mariza.webp";
import Timo_J from "../Resources/Pics/team_photos_25_webp/timo_j.webp";
import Anthi from "../Resources/Pics/team_photos_25_webp/anthi.webp";
import Cala from "../Resources/Pics/team_photos_25_webp/cala.webp";
import Gratas from "../Resources/Pics/team_photos_25_webp/gratas.webp";
import Jayden from "../Resources/Pics/team_photos_25_webp/jayden.webp";
import Leon from "../Resources/Pics/team_photos_25_webp/leon.webp";
import Lorenzo from "../Resources/Pics/team_photos_25_webp/lorenzo.webp";
import Merijn from "../Resources/Pics/team_photos_25_webp/merijn.webp";
import Mohammed from "../Resources/Pics/team_photos_25_webp/mohammed.webp";
import Nienke from "../Resources/Pics/team_photos_25_webp/nienke.webp";
import Julian from "../Resources/Pics/team_photos_25_webp/julian.webp";
import Rolf from "../Resources/Pics/team_photos_25_webp/rolf.webp";
import Ruben from "../Resources/Pics/team_photos_25_webp/ruben.webp";
import Steven from "../Resources/Pics/team_photos_25_webp/steven.webp";
import Sander from "../Resources/Pics/team_photos_25_webp/sander.webp";
import Stijn from "../Resources/Pics/team_photos_25_webp/stijn.webp";
import Timo from "../Resources/Pics/team_photos_25_webp/timo.webp";
import Yannick from "../Resources/Pics/team_photos_25_webp/yannick.webp";
import Erik from "../Resources/Pics/team_photos_25_webp/erik.webp";

import Willy_alt from "../Resources/Pics/team_photos_25_webp_small/willy.webp";
import Tom_alt from "../Resources/Pics/team_photos_25_webp_small/tom.webp";
import Julliette_alt from "../Resources/Pics/team_photos_25_webp_small/julliette.webp";
import Daan_alt from "../Resources/Pics/team_photos_25_webp_small/daan.webp";
import Egbert_alt from "../Resources/Pics/team_photos_25_webp_small/egbert.webp";
import Etjen_alt from "../Resources/Pics/team_photos_25_webp_small/etjen.webp";
import Jort_alt from "../Resources/Pics/team_photos_25_webp_small/jort.webp";
import Mariza_alt from "../Resources/Pics/team_photos_25_webp_small/mariza.webp";
import Timo_J_alt from "../Resources/Pics/team_photos_25_webp_small/timo_j.webp";
import Anthi_alt from "../Resources/Pics/team_photos_25_webp_small/anthi.webp";
import Cala_alt from "../Resources/Pics/team_photos_25_webp_small/cala.webp";
import Gratas_alt from "../Resources/Pics/team_photos_25_webp_small/gratas.webp";
import Jayden_alt from "../Resources/Pics/team_photos_25_webp_small/jayden.webp";
import Leon_alt from "../Resources/Pics/team_photos_25_webp_small/leon.webp";
import Lorenzo_alt from "../Resources/Pics/team_photos_25_webp_small/lorenzo.webp";
import Merijn_alt from "../Resources/Pics/team_photos_25_webp_small/merijn.webp";
import Mohammed_alt from "../Resources/Pics/team_photos_25_webp_small/mohammed.webp";
import Nienke_alt from "../Resources/Pics/team_photos_25_webp_small/nienke.webp";
import Julian_alt from "../Resources/Pics/team_photos_25_webp_small/julian.webp";
import Rolf_alt from "../Resources/Pics/team_photos_25_webp_small/rolf.webp";
import Ruben_alt from "../Resources/Pics/team_photos_25_webp_small/ruben.webp";
import Steven_alt from "../Resources/Pics/team_photos_25_webp_small/steven.webp";
import Sander_alt from "../Resources/Pics/team_photos_25_webp_small/sander.webp";
import Stijn_alt from "../Resources/Pics/team_photos_25_webp_small/stijn.webp";
import Timo_alt from "../Resources/Pics/team_photos_25_webp_small/timo.webp";
import Yannick_alt from "../Resources/Pics/team_photos_25_webp_small/yannick.webp";
import Erik_alt from "../Resources/Pics/team_photos_25_webp_small/erik.webp";

function TeamScroll() {

  const [isSmallScreen, setIsSmallScreen] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      setIsSmallScreen(window.innerWidth < 700);
    };
    handleResize();
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);
  const portraits = [
    Willy, Tom, Julliette, Daan, Egbert, Etjen, Jort, Mariza, Timo_J, Anthi, Cala,
    Gratas, Jayden, Leon, Lorenzo, Merijn, Mohammed, Nienke, Julian, Rolf, Ruben,
    Steven, Sander, Stijn, Timo, Yannick, Erik, Willy, Tom, Julliette, Daan, Egbert,  Etjen, Jort, Mariza
  ];

  const altPortraits = [
    Willy_alt, Tom_alt, Julliette_alt, Daan_alt, Egbert_alt, Etjen_alt, Jort_alt,
    Mariza_alt, Timo_J_alt, Anthi_alt, Cala_alt, Gratas_alt, Jayden_alt, Leon_alt,
    Lorenzo_alt, Merijn_alt, Mohammed_alt, Nienke_alt, Julian_alt, Rolf_alt,
    Ruben_alt, Steven_alt, Sander_alt, Stijn_alt, Timo_alt, Yannick_alt, Erik_alt,  Willy_alt, Tom_alt, Julliette_alt, Daan_alt, Egbert_alt, Etjen_alt, Jort_alt,
    Mariza_alt
  ];

  const portraitsList = portraits.map((portrait, index) => {
    const altPortrait = altPortraits[index];
    return (
      <div className="slide" key={portrait}>
        <img
         src={isSmallScreen ? altPortrait : portrait}
         alt="Portrait"
         className="portrait"
         width={isSmallScreen ? "200rem" : "350rem"}
         loading="eager"
         effect="blur"
        />
      </div>
    );
  });
  return (
    <>
      <div className="section-title">
        <h1>
          Meet the <text className="emphasis"> /people </text> <br></br>
          <h2>
            That will <text className=""> get us </text> there
          </h2>
        </h1>
      </div>
      <div className="container-fluid team overflow-hidden">
        <div className="row d-flex align-items-center justify-content-center">
          <div className="col-12 slider">
            <div className="slide-track">
              {portraitsList}
            </div>
          </div>
          <Link to="/team25">
            <span className="title">
              <h1 data-aos="fade-right" data-aos-duration="1000">
                TEAM{" "}
              </h1>
              <h1 data-aos="fade-left" data-aos-duration="1000">
                <text className="emphasis"> /25</text>
              </h1>
            </span>{" "}
          </Link>
        </div>
      </div>
    </>
  );
}

export default TeamScroll;
