import React from "react";
import "./CarReveal.scss";

import banner from '../../Resources/Pics/wt.png';
import cover from '../../Resources/Pics/D1.png'

let become = "become@solarracinggroningen.nl";
let acquisition = "acquisition@solarracinggroningen.nl";
let comms = "pers@solarracinggroningen.nl";
let info = "info@solarracinggroningen.nl";

const CarReveal = () => {
  const duration = 1000;

  return (
    <div>
      <div
        data-aos="fade-left"
        data-aos-duration={duration}
        className="container simple-section mt-5"
      >
        <div className="row simple-section-header">
          <h1>
            <text className="emphasis">/TDSR</text> Car Reveal 2023
          </h1>          
        </div>
        <div className="row simple-section-body">
            <h2>Driven To Get There</h2>
            <p className="aux-p">Top Dutch Solar Racing invites you to the car reveal of our third-generation car!</p>
        </div>
        <div className="row simple-section-body">
            <div className="col-md-6 order-sm-first pb-5">
                <img src={cover} className="invitation-img"></img>
            </div>
            <div className="col-md-6 order-sm-first pb-5">
                <h4>You can redeem your coupon here!</h4>
                <iframe src="https://www.ticketkantoor.nl/shop/solarracing&embed=1" name="ticketshop" className="invitation"></iframe>
            </div>
        </div>
      </div>
      
    </div>
  );
};

export default CarReveal;
