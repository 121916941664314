import React from "react";
import "./RecruitmentPage.scss";
import { LazyLoadImage } from "react-lazy-load-image-component";

import recruitmentImage from "../../Resources/Pics/recruitment/finish_adelaide.webp";
import PosDescElement from "./PosDescElement";

import Aero_Engineer from "../../Resources/Pics/recruitment/PDFs/Aero_Engineer_PDF_compressed.pdf";
import Electrical_Engineer from "../../Resources/Pics/recruitment/PDFs/Electrical_Engineer_PDF_compressed.pdf";
import Event_planner from "../../Resources/Pics/recruitment/PDFs/Event_planner_PDF_compressed.pdf";
import Graphic_designer from "../../Resources/Pics/recruitment/PDFs/Graphic_designer_PDF_compressed.pdf";
import Marketing_Manager from "../../Resources/Pics/recruitment/PDFs/Marketing_Manager_PDF_compressed.pdf";
import Mechanical_Engineer from "../../Resources/Pics/recruitment/PDFs/Mechanical_Engineer_PDF_compressed.pdf";
import Media_Team from "../../Resources/Pics/recruitment/PDFs/Media_Team_PDF_compressed.pdf";
import Communications from "../../Resources/Pics/recruitment/PDFs/Communications_PDF.pdf";

import Partner_relations from "../../Resources/Pics/recruitment/PDFs/Partner_relations_PDF_compressed.pdf";
import Photographer from "../../Resources/Pics/recruitment/PDFs/Photographer_PDF_compressed.pdf";
import Software_Engineer from "../../Resources/Pics/recruitment/PDFs/Software_Engineer_PDF_compressed.pdf";
import Team_manager from "../../Resources/Pics/recruitment/PDFs/Team_manager_PDF_compressed.pdf";
import Strategist from "../../Resources/Pics/recruitment/PDFs/Strategist_PDF_compressed.pdf";
import Structural_Engineer from "../../Resources/Pics/recruitment/PDFs/Structural_Engineer_PDF_compressed.pdf";
import Technical_Manager from "../../Resources/Pics/recruitment/PDFs/Technical_Manager_PDF_compressed.pdf";
import Travel_Logistics from "../../Resources/Pics/recruitment/PDFs/Travel_Logistics_PDF_compressed.pdf";
import Videographer from "../../Resources/Pics/recruitment/PDFs/Videographer_PDF_compressed.pdf";
import { HashLink } from "react-router-hash-link/dist/react-router-hash-link.cjs.production";

function RecruitmentPage() {
  const formUrl = "https://forms.gle/r1AcyHYwwopGh4yy6";
  const openDaysUrl = "https://forms.gle/yfrAhHmAcJh3fPbQ9";

  const YoutubeVideo = ({ videoId }) => {
    const videoUrl = `https://www.youtube.com/embed/${videoId}?autoplay=1&loop=1&controls=0&mute=1&disablekb=1&playlist=${videoId}`;

    return (
      <div>
        <iframe
          width="100%"
          height="350"
          src={videoUrl}
          title="YouTube video player"
          frameBorder="0"
          allow="autoplay; loop; fullscreen"
          allowFullScreen
        ></iframe>
      </div>
    );
  };

  return (
    <div>
      <div className="container simple-section mt-5">
        <div className="row simple-section-header">
          <h1>
            Join the <text className="emphasis">/Next Generation</text> Of Solar
            Racing
          </h1>
        </div>
        <div className="row simple-section-body">
          <div className="overflow-hidden">
            <div className="row mt-2">
              <div className="col-md-6 order-sm-first">
                <p>
                  Are you the next team member of Top Dutch Solar Racing? We're
                  in search of individuals who not only embrace curiosity and
                  motivation but thrive as out-of-the-box thinkers, constantly
                  pushing their limits. This is your chance to immerse yourself
                  in a dynamic learning environment, collaborate with
                  industries, and explore the latest equipment to shape the
                  future of solar racing. If you want to be part of this
                  adventure, check out our exciting vacancies and submit your
                  application now!
                </p>
                <div className="contact-special overflow-hidden center-button">
                  <HashLink to="/faq" target="_blank">
                    <button className="form-menu">
                      Frequently Asked Questions
                    </button>
                  </HashLink>
                </div>
                <br></br>
              </div>
              <div className="col-md-6 order-sm-second">
                <YoutubeVideo videoId="o-9_a2NMD7s" />
              </div>
            </div>
            <br></br>
            <h2 className="section-title">
              Available <text className="emphasis">/Positions</text>
            </h2>
          </div>
          <div className="center-button justify-content-center simple-section-body">
            <PosDescElement title="Comms. member" link={Communications} />
          </div>

          <div className="justify-content-center simple-section-body center-button">
            <button
              onClick={() => window.open(formUrl, "_blank")}
              className="form-menu"
            >
              Open Registration Form
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default RecruitmentPage;
