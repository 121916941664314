import "./Cars.scss";
import GL from "../Resources/Pics/cars/GreenLightning.webp";
import GS from "../Resources/Pics/cars/GreenSpirit.webp";
import GT from"../Resources/Pics/cars/GreenThunder2.webp";
import Gnew from "../Resources/Pics/cars/Gnew.webp";
import "aos/dist/aos.css";
import { HashLink } from "react-router-hash-link";
import { useNavigate } from "react-router-dom";
import React from 'react';

const Card = () => {
  const navigate = useNavigate();

  const handleScrollToSection = (hash) => {
    navigate(`/${hash}`);
    document.querySelector(hash).scrollIntoView({ behavior: "smooth", block: "center" });
  };
  return (
    <>
      <div className="section-title">
      <h1>
          THE <text className="emphasis"> /cars </text> <br></br>
        </h1>
        <h2>
          That <text className=""> got us </text> there
        </h2>
      </div>
      <div className="d-flex align-items-center justify-content-center container-fluid smallabout background simple-section section">
        <div className="simple-section-header align-items-center rounded-4">
          <div className="card">
            <p style={{backgroundImage: `url(${GL})`}}  onClick={() => handleScrollToSection('green-lightning')} >
              <span>GREEN LIGHTING</span>
            </p>
            <p style={{backgroundImage: `url(${GS})`}} onClick={() => handleScrollToSection('green-spirit')}>
              <span style={{textShadow: `none`}}>GREEN SPIRIT</span>
            </p>
            <p style={{backgroundImage: `url(${GT})`}} onClick={() => handleScrollToSection('green-thunder')}>
              <span>GREEN THUNDER</span>
            </p>
          </div>
        </div>
      </div>
    </>
  );
}


export default Card;
