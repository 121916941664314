import "./Partners.scss";

import { useRef, useEffect, useState } from "react";

function PartnersInfo() {
  const YoutubeVideo = ({ videoId }) => {
    const videoUrl = `https://www.youtube.com/embed/${videoId}?autoplay=0&loop=1&controls=0&mute=1&disablekb=1&playlist=${videoId}`;

    return (
      <div>
        <iframe
          width="100%"
          height="220"
          src={videoUrl}
          title="YouTube video player"
          frameBorder="0"
          allow="autoplay; loop; fullscreen"
          allowFullScreen
        ></iframe>
      </div>
    );
  };

  return (
    <>
      <div className="container simple-section">
        <div className="row simple-section-header">
          <h1>Join us</h1>
        </div>
        <div className="row simple-section-body">
          <p>
            Support our student solar racing team for a unique and exciting
            opportunity to showcase your brand and values. We design, build, and
            race solar-powered vehicles in international competitions,
            attracting thousands of spectators and media coverage. Partner with
            us to align with a team that values sustainability and innovation,
            while also contributing to a brighter, cleaner{" "}
            <text className="emphasis"> /greener</text> future.
          </p>
        </div>
        <div className="row simple-section-body">
          <div className="col-md-4 ">
            <YoutubeVideo videoId="A1zilq_5Efo" />
          </div>

          <div className="col-md-4 ">
            <YoutubeVideo videoId="z7UTankt1hY" />{" "}
          </div>
          <div className="col-md-4">
            <YoutubeVideo videoId="Vklkq7tvxE8" />{" "}
          </div>
        </div>
      </div>
    </>
  );
}

export default PartnersInfo;
