import "./Teams.scss";
import React from "react";
import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";

// import team pics
import Ricky from "../../Resources/Pics/team_photos_23_webp/ricky.webp";
import Micha from "../../Resources/Pics/team_photos_23_webp/micha.webp";
import Erik from "../../Resources/Pics/team_photos_23_webp/erik.webp";
import Camiel from "../../Resources/Pics/team_photos_23_webp/camiel.webp";
import Andreea from "../../Resources/Pics/team_photos_23_webp/andreea.webp";
import Maha from "../../Resources/Pics/team_photos_23_webp/maha.webp";
import Nina from "../../Resources/Pics/team_photos_23_webp/nina.webp";
import Loeka from "../../Resources/Pics/team_photos_23_webp/loeka.webp";
import Peter from "../../Resources/Pics/team_photos_23_webp/peter.webp";
import Anna from "../../Resources/Pics/team_photos_23_webp/anna.webp";
import Jakob from "../../Resources/Pics/team_photos_23_webp/jakob.webp";
import Huan from "../../Resources/Pics/team_photos_23_webp/huan.webp";
import Marko from "../../Resources/Pics/team_photos_23_webp/marko.webp";
import Matthias from "../../Resources/Pics/team_photos_23_webp/matthias.webp";
import Mourad from "../../Resources/Pics/team_photos_23_webp/mourad.webp";
import Stan from "../../Resources/Pics/team_photos_23_webp/stan.webp";
import Kees from "../../Resources/Pics/team_photos_23_webp/kees.webp";
import Jochem from "../../Resources/Pics/team_photos_23_webp/jochem.webp";
import Themba from "../../Resources/Pics/team_photos_23_webp/themba.webp";
import Laura from "../../Resources/Pics/team_photos_23_webp/laura.webp";
import Ayzo from "../../Resources/Pics/team_photos_23_webp/ayzo.webp";
import Ian from "../../Resources/Pics/team_photos_23_webp/ian.webp";
import Andrei from "../../Resources/Pics/team_photos_23_webp/andrei.webp";
import Vlad from "../../Resources/Pics/team_photos_23_webp/vlad.webp";
import Wout from "../../Resources/Pics/team_photos_23_webp/wout.webp";
import Jurre from "../../Resources/Pics/team_photos_23_webp/jurre.webp";
import Amy from "../../Resources/Pics/team_photos_23_webp/amy.webp";
import Nieck from "../../Resources/Pics/team_photos_23_webp/nieck.webp";

//import alt for portraits
import Micha_alt from "../../Resources/Pics/team_photos_23_webp_small/micha.webp";
import Ricky_alt from "../../Resources/Pics/team_photos_23_webp_small/ricky.webp";
import Erik_alt from "../../Resources/Pics/team_photos_23_webp_small/erik.webp";
import Camiel_alt from "../../Resources/Pics/team_photos_23_webp_small/camiel.webp";
import Andreea_alt from "../../Resources/Pics/team_photos_23_webp_small/andreea.webp";
import Maha_alt from "../../Resources/Pics/team_photos_23_webp_small/maha.webp";
import Nina_alt from "../../Resources/Pics/team_photos_23_webp_small/nina.webp";
import Loeka_alt from "../../Resources/Pics/team_photos_23_webp_small/loeka.webp";
import Peter_alt from "../../Resources/Pics/team_photos_23_webp_small/peter.webp";
import Anna_alt from "../../Resources/Pics/team_photos_23_webp_small/anna.webp";
import Jakob_alt from "../../Resources/Pics/team_photos_23_webp_small/jakob.webp";
import Huan_alt from "../../Resources/Pics/team_photos_23_webp_small/huan.webp";
import Marko_alt from "../../Resources/Pics/team_photos_23_webp_small/marko.webp";
import Matthias_alt from "../../Resources/Pics/team_photos_23_webp_small/matthias.webp";
import Mourad_alt from "../../Resources/Pics/team_photos_23_webp_small/mourad.webp";
import Stan_alt from "../../Resources/Pics/team_photos_23_webp_small/stan.webp";
import Kees_alt from "../../Resources/Pics/team_photos_23_webp_small/kees.webp";
import Jochem_alt from "../../Resources/Pics/team_photos_23_webp_small/jochem.webp";
import Themba_alt from "../../Resources/Pics/team_photos_23_webp_small/themba.webp";
import Laura_alt from "../../Resources/Pics/team_photos_23_webp_small/laura.webp";
import Ayzo_alt from "../../Resources/Pics/team_photos_23_webp_small/ayzo.webp";
import Ian_alt from "../../Resources/Pics/team_photos_23_webp_small/ian.webp";
import Andrei_alt from "../../Resources/Pics/team_photos_23_webp_small/andrei.webp";
import Vlad_alt from "../../Resources/Pics/team_photos_23_webp_small/vlad.webp";
import Amy_alt from "../../Resources/Pics/team_photos_23_webp_small/amy.webp";
import Nieck_alt from "../../Resources/Pics/team_photos_23_webp_small/nieck.webp";

import we_are_tdsr from "../../Resources/Pics/teamphoto/we_are_23.svg";

// get team linkedin links
const linkErik = "https://www.linkedin.com/in/erik-westerhoff-452b1111/";
const linkRicky = "https://nl.linkedin.com/in/rick-eichelsheim-a4212a1b7";
const linkMicha = "https://www.linkedin.com/in/micha-leonardo-v-961b8610b/";
const linkCamiel = "https://nl.linkedin.com/in/camiel-waverijn-897670110";
const linkAndreea = "https://nl.linkedin.com/in/andreea-mihaela-toca";
const linkMaha = "https://cy.linkedin.com/in/maha-christofi-2b3042198";
const linkLoeka = "https://nl.linkedin.com/in/loeka-demkes-238320228";
const linkNina = "https://nl.linkedin.com/in/nina-dierks-a53965219";
const linkPeter = "https://nl.linkedin.com/in/peter-martinus-van-tol";
const linkAnna = "";
const linkMatthias = "https://nl.linkedin.com/in/matthias-van-burg-7b4435247";
const linkIan = "https://nl.linkedin.com/in/iansoede";
const linkAyzo = "https://nl.linkedin.com/in/ayzo-wiertsema-3b5227177";
const linkJochem = "https://nl.linkedin.com/in/jochem-hulsebos-497578203";
const linkMourad = "https://nl.linkedin.com/in/mourad-mougou-47b7ba198";
const linkKees = "";
const linkStan = "https://nl.linkedin.com/in/stan-knollema-065645207";
const linkJakob = "https://www.linkedin.com/in/jakobdeboer/";
const linkHuan = "https://www.linkedin.com/in/huan-l-02529b17b/";
const linkMarko = "https://nl.linkedin.com/in/marko-nieland-282b72198";
const linkLaura = "https://nl.linkedin.com/in/laura-dlf";
const linkThemba = "https://nl.linkedin.com/in/themba-vriezekolk";
const linkAndrei = "https://nl.linkedin.com/in/andrei-istudor";
const linkVlad = "https://nl.linkedin.com/in/vlad-iftime";
const linkWout = "https://www.linkedin.com/in/wout-veltman/";
const linkJurre = "https://www.linkedin.com/in/jurre-sijpersma-29b8a9220/";
const linkAmy = "https://www.linkedin.com/in/amy-kloosterman-367702223/";
const linkNieck = "";

function NavBar({ sections }) {
  const scrollToSection = (id) => {
    const element = document.getElementById(id);
    const yOffset = -200; // Adjust this value as needed
    const yPosition = element.getBoundingClientRect().top + window.pageYOffset + yOffset;
    
    window.scrollTo({
      top: yPosition,
      behavior: "smooth",
    });
  };
  return (
    
    <div className="team-section container">
      <div className="row team-section-header justify-content-around">
        <h1>
          {"Find us with a "}
          <text className="emphasis">{"/click"} </text>
        </h1>
      </div>
      <div className="team-member">
        {sections.map((section) => (
          <button
            key={section.id}
            onClick={() => scrollToSection(section.id)}
            className="teamOp-button"
            >
            {section.name}
          </button>
        
        ))}
      </div>
    </div>
  );
}

// function to create team member
function TeamMember(props) {
  return (
    <div className="team-member">
      <a href={props.link} target="_blank" rel="noreferrer">
        <img
          src={props.pic}
          placeholderSrc={props.pic_alt}
          alt={props.name}
          className="img-fluid member-pic"
          effect="blur"
        />
        <h3>{props.name}</h3>
      </a>
      <p>{props.role}</p>
    </div>
  );
}

// function to create team section with unknown number of children
function TeamSection(props) {
  return (
    <div id={props.id} className="team-section container" data-aos="fade-up">
      <div className="row team-section-header justify-content-around">
        <h1>
          {props.preEmphasis}
          <text className="emphasis">{props.Emphasis}</text>
          {props.postEmphasis}
        </h1>
        {/* loop trough the children prop */}
        {props.members.map((child, index) => {
          return (
            <div className="col-md-4" key={index}>
              {child}
            </div>
          );
        })}
      </div>
    </div>
  );
}

function Teams() {
  const sections = [
    { id: "management", name: "Management Team" },
    { id: "software", name: "Software Team" },
    { id: "operations", name: "Operations Team" },
    { id: "communication", name: "Communication Team" },
    { id: "acquisitions", name: "Acquisitions Team" },
    { id: "aerodynamics", name: "Aerodynamics Team" },
    { id: "mechanics", name: "Mechanics Team" },
    { id: "strategy", name: "Strategy Team" },
    { id: "electronics", name: "Electronics Team" },
    { id: "coach", name: "Team coach" },
  ];
  const teamSections = [
    TeamSection({
      id: "management",
      preEmphasis: "The ",
      Emphasis: "/management ",
      postEmphasis: "team",
      members: [
        TeamMember({
          name: "Rick Eichelsheim",
          role: "Team Manager",
          pic: Ricky,
          pic_alt: Ricky_alt,
          link: linkRicky,
        }),
        TeamMember({
          name: "Micha Leonardo van Beek",
          role: "Technical Team Manager",
          pic: Micha,
          pic_alt: Micha_alt,
          link: linkMicha,
        }),
      ],
    }),

    TeamSection({
      id: "operations",
      preEmphasis: "The ",
      Emphasis: "/operations ",
      postEmphasis: "team",
      members: [
        TeamMember({
          name: "Andreea Toca",
          role: "Logistics and Operations",
          pic: Andreea,
          pic_alt: Andreea_alt,
          link: linkAndreea,
        }),
        TeamMember({
          name: "Camiel Waverijn",
          role: "Logistics and Operations",
          pic: Camiel,
          pic_alt: Camiel_alt,
          link: linkCamiel,
        }),
      ],
    }),

    TeamSection({
      id: "communication",
      preEmphasis: "The ",
      Emphasis: "/communication ",
      postEmphasis: "team",
      members: [
        TeamMember({
          name: "Jurre Sijpersma",
          role: "Communication and Media",
          pic: Jurre,
          pic_alt: Vlad_alt,
          link: linkJurre,
        }),
        TeamMember({
          name: "Loeka Demkes",
          role: "Communication and Marketing",
          pic: Loeka,
          pic_alt: Loeka_alt,
          link: linkLoeka,
        }),
        TeamMember({
          name: "Maha Christofi",
          role: "Communication and Marketing",
          pic: Maha,
          pic_alt: Maha_alt,
          link: linkMaha,
        }),
        TeamMember({
          name: "Nina Dierks",
          role: "Communication and Marketing",
          pic: Nina,
          pic_alt: Nina_alt,
          link: linkNina,
        }),
        TeamMember({
          name: "Amy Kloosterman",
          role: "Photography",
          pic: Amy,
          pic_alt: Amy_alt,
          link: linkAmy,
        }),
        TeamMember({
          name: "Nieck van Dijk",
          role: "Videography",
          pic: Nieck,
          pic_alt: Nieck_alt,
          link: linkNieck,
        }),
      ],
    }),

    TeamSection({
      id: "acquisitions",
      preEmphasis: "The ",
      Emphasis: "/acquisitions  ",
      postEmphasis: "team",
      members: [
        TeamMember({
          name: "Anna Sikkema",
          role: "Acquisitions",
          pic: Anna,
          pic_alt: Anna_alt,
          link: linkAnna,
        }),
        TeamMember({
          name: "Peter van Tol",
          role: "Acquisitions and Finance",
          pic: Peter,
          pic_alt: Peter_alt,
          link: linkPeter,
        }),
      ],
    }),

    TeamSection({
      id: "aerodynamics",
      preEmphasis: "The ",
      Emphasis: "/aerodynamics ",
      postEmphasis: "team",
      members: [
        TeamMember({
          name: "Ayzo Wiertsema",
          role: "Aerodynamics Design",
          pic: Ayzo,
          pic_alt: Ayzo_alt,
          link: linkAyzo,
        }),
        TeamMember({
          name: "Ian Soede",
          role: "Aerodynamics Design",
          pic: Ian,
          pic_alt: Ian_alt,
          link: linkIan,
        }),
        TeamMember({
          name: "Matthias van Burg",
          role: "Aerodynamics Design and Structural Design",
          pic: Matthias,
          pic_alt: Matthias_alt,
          link: linkMatthias,
        }),
      ],
    }),

    TeamSection({
      id: "mechanics",
      preEmphasis: "The ",
      Emphasis: "/mechanics ",
      postEmphasis: "team",
      members: [
        TeamMember({
          name: "Jochem Hulsebos",
          role: "Mechanical Engineering",
          pic: Jochem,
          pic_alt: Jochem_alt,
          link: linkJochem,
        }),
        TeamMember({
          name: "Kees Hovinga",
          role: "Mechanical Engineering and Structural Design",
          pic: Kees,
          pic_alt: Kees_alt,
          link: linkKees,
        }),
        TeamMember({
          name: "Mourad Mougou",
          role: "Mechanical Engineering",
          pic: Mourad,
          pic_alt: Mourad_alt,
          link: linkMourad,
        }),
        TeamMember({
          name: "Stan Knollema",
          role: "Mechanical Engineering",
          pic: Stan,
          pic_alt: Stan_alt,
          link: linkStan,
        }),
      ],
    }),

    TeamSection({
      id: "strategy",
      preEmphasis: "The ",
      Emphasis: "/strategy ",
      postEmphasis: "team",
      members: [
        TeamMember({
          name: "Huan Li",
          role: "Strategy",
          pic: Huan,
          pic_alt: Huan_alt,
          link: linkHuan,
        }),
        TeamMember({
          name: "Jakob de Boer",
          role: "Strategy",
          pic: Jakob,
          pic_alt: Jakob_alt,
          link: linkJakob,
        }),
      ],
    }),
    TeamSection({
      id: "electronics",
      preEmphasis: "The ",
      Emphasis: "/electronics ",
      postEmphasis: "team",
      members: [
        TeamMember({
          name: "Laura de la Fuente Esteban",
          role: "Electrical Engineering",
          pic: Laura,
          pic_alt: Laura_alt,
          link: linkLaura,
        }),
        TeamMember({
          name: "Marko Nieland",
          role: "Electrical Engineering and Acquisition",
          pic: Marko,
          pic_alt: Marko_alt,
          link: linkMarko,
        }),
        TeamMember({
          name: "Themba Vriezekolk",
          role: "Electrical Engineering",
          pic: Themba,
          pic_alt: Themba_alt,
          link: linkThemba,
        }),
        TeamMember({
          name: "Wout Veltman",
          role: "Powertrain Engineering",
          pic: Wout,
          pic_alt: Vlad_alt,
          link: linkWout,
        }),
      ],
    }),

    TeamSection({
      id: "software",
      preEmphasis: "The ",
      Emphasis: "/software ",
      postEmphasis: "team",
      members: [
        TeamMember({
          name: "Andrei Istudor",
          role: "Electrical and Software Engineering",
          pic: Andrei,
          pic_alt: Andrei_alt,
          link: linkAndrei,
        }),
        TeamMember({
          name: "Vlad Iftime",
          role: "Electrical and Software Engineering",
          pic: Vlad,
          pic_alt: Vlad_alt,
          link: linkVlad,
        }),
      ],
    })
  ]
  const shuffledTeamSections = teamSections.sort(() => Math.random() - 0.5);
  return (
    <>
      <div className="hero-box-23 text-center" id="parent">
        <div
          data-aos="fade-down"
          data-aos-anchor="#parent"
          data-aos-duration="1000"
          className="hero-text-bottom"
        >
          <img src={we_are_tdsr} alt="We are TDSR" effect="blur" />
        </div>
      </div>

      <div className="team-section container" data-aos="fade-up">
        <div className="row team-section-header justify-content-around">
          <h1>
            Meet our <text className="emphasis">/team</text>
          </h1>
          <h2 className="col">
            We are Top Dutch Solar Racing, a driven student team from Groningen.
            What makes us unique is the fact that we are the only team in the
            Netherlands whose members come from different levels of education
            (MBO, HBO, WO). Together we are building a solar-powered car from
            scratch with the goal of winning the Bridgestone World Solar
            Challenge.
          </h2>
        </div>
      </div>
      <NavBar sections={sections} />
      {shuffledTeamSections.map((section, index) => (
        <React.Fragment key={index}>{section}</React.Fragment>
      ))}
      {TeamSection({
        id: "coach",
        preEmphasis: "The ",
        Emphasis: "/mentor",
        postEmphasis: "",
        members: [
          TeamMember({
            name: "Erik Westerhoff",
            role: "Team mentor",
            pic: Erik,
            pic_alt: Erik_alt,
            link: linkErik,
          }),
        ],
      })}
    </>
  );
}

export default Teams;
