function TermsAndConditions() {
  return (
    <div className="container simple-section">
      <div className="row simple-section-header">
        <h1>Terms and Conditions of Stichting Solar Racing Groningen's</h1>
      </div>
      <div className="row simple-section-body">
        <div className="col-12">
          <p>
            <h3>Crowdfunding Campaign Donations:</h3> All donations made to
            Stichting Solar Racing Groningen's crowdfunding campaign are
            voluntary and non-refundable. The non-profit organization running
            the campaign is not obligated to provide any goods or services in
            return for donations.
            <h3> Use of Funds:</h3> The proceeds of the donations will be used
            solely for the purpose of supporting the non-profit organization's
            logistical aspects of the project and technology development and
            research. Stichting Solar Racing Groningen will use best efforts to
            ensure that the funds are used effectively and efficiently towards
            the stated purpose.
            <h3>Public Disclosure:</h3> The donors acknowledge that their
            donation amount will be placed on the website solarfunds.nl as a
            public disclosure. The donor's name and email address may also be
            displayed on the website unless the donor requests otherwise.
            <h3> Tax Deductibility: </h3>Donations made to Stichting Solar
            Racing Groningen may be tax-deductible in accordance with applicable
            tax laws. However, it is the responsibility of the donor to consult
            with a tax professional regarding the tax implications of their
            donation.
            <h3>Privacy:</h3> Stichting Solar Racing Groningen will collect and
            use personal information of donors solely for the purpose of
            processing their donation and keeping them informed about the
            campaign. The non-profit organization will not share or sell donor
            information to any third parties.
            <h3> Liability:</h3> Stichting Solar Racing Groningen shall not be
            liable for any damages or losses arising from the use of the funds
            donated by the donors. Stichting Solar Racing Groningen shall not be
            liable for any technical malfunctions or issues that may arise
            during the course of the crowdfunding campaign.
            <h3>Modifications:</h3>
            Stichting Solar Racing Groningen reserves the right to modify or
            update these terms and conditions at any time. Any changes made to
            the terms and conditions will be effective immediately upon posting.
            <h3>Governing Law:</h3> These terms and conditions shall be governed
            by and construed in accordance with the laws of the Netherlands. By
            donating to the crowdfunding campaign, donors agree to these terms
            and conditions.
            <br></br>If you have any questions about these terms and conditions
            or the crowdfunding campaign, please contact us at
            info@solarracinggroningen.nl.
          </p>
        </div>
      </div>
    </div>
  );
}

export default TermsAndConditions;
