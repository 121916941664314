import "./Teams.scss";
import React from "react";
import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";

// import team pics
import Willy from "../../Resources/Pics/team_photos_25_webp/willy.webp";
import Tom from "../../Resources/Pics/team_photos_25_webp/tom.webp";
import Julliette from "../../Resources/Pics/team_photos_25_webp/julliette.webp";
import Daan from "../../Resources/Pics/team_photos_25_webp/daan.webp";
import Egbert from "../../Resources/Pics/team_photos_25_webp/egbert.webp";
import Etjen from "../../Resources/Pics/team_photos_25_webp/etjen.webp";
import Jort from "../../Resources/Pics/team_photos_25_webp/jort.webp";
import Mariza from "../../Resources/Pics/team_photos_25_webp/mariza.webp";
import Timo_J from "../../Resources/Pics/team_photos_25_webp/timo_j.webp";
import Anthi from "../../Resources/Pics/team_photos_25_webp/anthi.webp";
import Cala from "../../Resources/Pics/team_photos_25_webp/cala.webp";
import Gratas from "../../Resources/Pics/team_photos_25_webp/gratas.webp";
import Jayden from "../../Resources/Pics/team_photos_25_webp/jayden.webp";
import Leon from "../../Resources/Pics/team_photos_25_webp/leon.webp";
import Lorenzo from "../../Resources/Pics/team_photos_25_webp/lorenzo.webp";
import Merijn from "../../Resources/Pics/team_photos_25_webp/merijn.webp";
import Mohammed from "../../Resources/Pics/team_photos_25_webp/mohammed.webp";
import Nienke from "../../Resources/Pics/team_photos_25_webp/nienke.webp";
import Julian from "../../Resources/Pics/team_photos_25_webp/julian.webp";
import Rolf from "../../Resources/Pics/team_photos_25_webp/rolf.webp";
import Ruben from "../../Resources/Pics/team_photos_25_webp/ruben.webp";
import Steven from "../../Resources/Pics/team_photos_25_webp/steven.webp";
import Sander from "../../Resources/Pics/team_photos_25_webp/sander.webp";
import Stijn from "../../Resources/Pics/team_photos_25_webp/stijn.webp";
import Timo from "../../Resources/Pics/team_photos_25_webp/timo.webp";
import Yannick from "../../Resources/Pics/team_photos_25_webp/yannick.webp";
import Erik from "../../Resources/Pics/team_photos_25_webp/erik.webp";

import Willy_alt from "../../Resources/Pics/team_photos_25_webp_small/willy.webp";
import Tom_alt from "../../Resources/Pics/team_photos_25_webp_small/tom.webp";
import Julliette_alt from "../../Resources/Pics/team_photos_25_webp_small/julliette.webp";
import Daan_alt from "../../Resources/Pics/team_photos_25_webp_small/daan.webp";
import Egbert_alt from "../../Resources/Pics/team_photos_25_webp_small/egbert.webp";
import Etjen_alt from "../../Resources/Pics/team_photos_25_webp_small/etjen.webp";
import Jort_alt from "../../Resources/Pics/team_photos_25_webp_small/jort.webp";
import Mariza_alt from "../../Resources/Pics/team_photos_25_webp_small/mariza.webp";
import Timo_J_alt from "../../Resources/Pics/team_photos_25_webp_small/timo_j.webp";
import Anthi_alt from "../../Resources/Pics/team_photos_25_webp_small/anthi.webp";
import Cala_alt from "../../Resources/Pics/team_photos_25_webp_small/cala.webp";
import Gratas_alt from "../../Resources/Pics/team_photos_25_webp_small/gratas.webp";
import Jayden_alt from "../../Resources/Pics/team_photos_25_webp_small/jayden.webp";
import Leon_alt from "../../Resources/Pics/team_photos_25_webp_small/leon.webp";
import Lorenzo_alt from "../../Resources/Pics/team_photos_25_webp_small/lorenzo.webp";
import Merijn_alt from "../../Resources/Pics/team_photos_25_webp_small/merijn.webp";
import Mohammed_alt from "../../Resources/Pics/team_photos_25_webp_small/mohammed.webp";
import Nienke_alt from "../../Resources/Pics/team_photos_25_webp_small/nienke.webp";
import Julian_alt from "../../Resources/Pics/team_photos_25_webp_small/julian.webp";
import Rolf_alt from "../../Resources/Pics/team_photos_25_webp_small/rolf.webp";
import Ruben_alt from "../../Resources/Pics/team_photos_25_webp_small/ruben.webp";
import Steven_alt from "../../Resources/Pics/team_photos_25_webp_small/steven.webp";
import Sander_alt from "../../Resources/Pics/team_photos_25_webp_small/sander.webp";
import Stijn_alt from "../../Resources/Pics/team_photos_25_webp_small/stijn.webp";
import Timo_alt from "../../Resources/Pics/team_photos_25_webp_small/timo.webp";
import Yannick_alt from "../../Resources/Pics/team_photos_25_webp_small/yannick.webp";
import Erik_alt from "../../Resources/Pics/team_photos_25_webp_small/erik.webp";

import we_are_tdsr from "../../Resources/Pics/teamphoto/we_are_25.svg";

// get team linkedin links
const linkErik = "https://www.linkedin.com/in/erik-westerhoff-452b1111/";
const linkWilly = "http://www.linkedin.com/in/daan-boonstra-";
const linkTom = "http://www.linkedin.com/in/tom-noordanus/";
const linkJulliette = "https://www.linkedin.com/in/jullietteevers/";
const linkAnthi = "https://www.linkedin.com/in/anthi-georgiadou/";
const linkCala = "https://www.linkedin.com/in/cala-gonz%C3%A1lez-6833502b7/";
const linkTimo = "https://timohoogewoonink.myportfolio.com/work";
const linkTimoJ = "https://www.linkedin.com/in/timo-jolman-b7a9b72a0/";
const linkJort = "https://www.linkedin.com/in/jort-frankena/";
const linkSteven = "https://www.linkedin.com/in/steven-de-roos-9a9488267";
const linkYannick = "https://www.linkedin.com/in/yannick-meijer-a90621256/";
const linkJayden = "https://www.linkedin.com/in/jayden-de-boer-0a33042a3?utm_source=share&utm_campaign=share_via&utm_content=profile&utm_medium=android_app";
const linkLorenzo = "https://www.lorenzozambelli.it";
const linkMariza =
  "https://www.linkedin.com/in/maria-mariza-christodoulou/";
const linkRolf = "https://www.linkedin.com/in/rolf-kok/";
const linkEgbert =
  "https://www.linkedin.com/in/egbert-menno-kuipers-66b303328?utm_source=share&utm_campaign=share_via&utm_content=profile&utm_medium=ios_app";
const linkGratas = "https://www.linkedin.com/in/gratas-kanord-35b3b4301/";
const linkLeon = "https://www.linkedin.com/in/leon-westra-48b742291/";
const linkJulian = "https://www.linkedin.com/in/julian-mounir-906346304/";
const linkRuben = "https://www.linkedin.com/in/ruben-korbeeck-36538b309/";
const linkMohammed =
  "https://www.linkedin.com/in/mohammed-sharifpour-286004195/";
const linkStijn = "https://www.linkedin.com/in/stijn-rekers-562154301/";
const linkMerijn = "https://www.linkedin.com/in/merijn-blonk-112a53206";
const linkSander = "https://www.linkedin.com/in/sander-laskewitz-b89875250/";
const linkDaan = "";
const linkEtjen = "https://www.linkedin.com/in/etjenreme/";
const linkNienke = "";

// function to create team member
function TeamMember(props) {
  return (
    <div className="team-member">
      <a href={props.link} target="_blank" rel="noreferrer">
        <img
          src={props.pic}
          placeholderSrc={props.pic_alt}
          alt={props.name}
          className="img-fluid member-pic"
          effect="blur"
        />
        <h3>{props.name}</h3>
      </a>
      <p>{props.role}</p>
    </div>
  );
}

function NavBar({ sections }) {
  const scrollToSection = (id) => {
    const element = document.getElementById(id);
    const yOffset = -200; // Adjust this value as needed
    const yPosition = element.getBoundingClientRect().top + window.pageYOffset + yOffset;
    window.scrollTo({
      top: yPosition,
      behavior: "auto",
    });
  };
  return (
    
    <div className="team-section container">
      <div className="row team-section-header justify-content-around">
        <h1>
          {"Find us with a "}
          <text className="emphasis">{"/click"} </text>
        </h1>
      </div>
      <div className="team-member">
        {sections.map((section) => (
          <button
            key={section.id}
            onClick={() => scrollToSection(section.id)}
            className="teamOp-button"
            >
            {section.name}
          </button>
        
        ))}
      </div>
    </div>
  );
}

// function to create team section with unknown number of children
function TeamSection(props) {
  return (
    <div id={props.id} className="team-section container" data-aos="fade-up">
      <div className="row team-section-header justify-content-around">
        <h1>
          {props.preEmphasis}
          <text className="emphasis">{props.Emphasis}</text>
          {props.postEmphasis}
        </h1>
        {/* loop trough the children prop */}
        {props.members.map((child, index) => {
          return (
            <div className="col-md-6" key={index}>
              {child}
            </div>
          );
        })}
      </div>
    </div>
  );
}

function Teams() {
  const sections = [
    { id: "management", name: "Management Team" },
    { id: "software", name: "Software Team" },
    { id: "operations", name: "Operations Team" },
    { id: "communication", name: "Communication Team" },
    { id: "acquisitions", name: "Acquisitions Team" },
    { id: "aerodynamics", name: "Aerodynamics Team" },
    { id: "mechanics", name: "Mechanics Team" },
    { id: "structural", name: "Structural Team" },
    { id: "strategy", name: "Strategy Team" },
    { id: "electronics", name: "Electronics Team" },
    { id: "coach", name: "Team coach" },
  ];
  const teamSections = [
    TeamSection({
      id: "software",
      preEmphasis: "The ",
      Emphasis: "/software ",
      postEmphasis: "team",
      members: [
        TeamMember({
          name: "Ruben Korbeeck",
          role: "Software & Electrical Engineer",
          pic: Ruben,
          pic_alt: Ruben_alt,
          link: linkRuben,
        }),
        TeamMember({
          name: "Julian Mounir",
          role: "Software & Electrical Engineer",
          pic: Julian,
          pic_alt: Julian_alt,
          link: linkJulian,
        }),
      ],
    }),

    TeamSection({
      id: "management",
      preEmphasis: "The ",
      Emphasis: "/management ",
      postEmphasis: "team",
      members: [
        TeamMember({
          name: "Daan Willem Boonstra",
          role: "Team Manager",
          pic: Willy,
          pic_alt: Willy_alt,
          link: linkWilly,
        }),
        TeamMember({
          name: "Tom Noordanus",
          role: "Technical Manager",
          pic: Tom,
          pic_alt: Tom_alt,
          link: linkTom,
        }),
        TeamMember({
          name: "Julliette Evers",
          role: "Marketing Manager",
          pic: Julliette,
          pic_alt: Julliette_alt,
          link: linkJulliette,
        }),
      ],
    }),

    TeamSection({
      id: "operations",
      preEmphasis: "The ",
      Emphasis: "/operations ",
      postEmphasis: "team",
      members: [
        TeamMember({
          name: "Mariza Christodoulou",
          role: "Operations officer",
          pic: Mariza,
          pic_alt: Mariza_alt,
          link: linkMariza,
        }),
      ],
    }),

    TeamSection({
      id: "communication",
      preEmphasis: "The ",
      Emphasis: "/communication ",
      postEmphasis: "team",
      members: [
        TeamMember({
          name: "Timo Hoogewoonink",
          role: "Communications and Media Officer",
          pic: Timo,
          pic_alt: Timo_alt,
          link: linkTimo,
        }),
        TeamMember({
          name: "Anthi Georgiadou",
          role: "Communications and Media Officer",
          pic: Anthi,
          pic_alt: Anthi_alt,
          link: linkAnthi,
        }),
        TeamMember({
          name: "Nienke de Wit",
          role: "Communications and Media Officer - Parttime",
          pic: Nienke,
          pic_alt: Nienke_alt,
          link: linkNienke,
        }),
      ],
    }),

    TeamSection({
      id: "acquisitions",
      preEmphasis: "The ",
      Emphasis: "/acquisitions  ",
      postEmphasis: "team",
      members: [
        TeamMember({
          name: "Stijn Rekers",
          role: "Partner Relations",
          pic: Stijn,
          pic_alt: Stijn_alt,
          link: linkStijn,
        }),
        TeamMember({
          name: "Jort Frankena",
          role: "Partner Relations",
          pic: Jort,
          pic_alt: Jort_alt,
          link: linkJort,
        }),
      ],
    }),

    TeamSection({
      id: "aerodynamics",
      preEmphasis: "The ",
      Emphasis: "/aerodynamics ",
      postEmphasis: "team",
      members: [
        TeamMember({
          name: "Egbert Menno Kuipers",
          role: "Aerodynamics Engineer & Structural Engineer",
          pic: Egbert,
          pic_alt: Egbert_alt,
          link: linkEgbert,
        }),
        TeamMember({
          name: "Cala Gonzalez",
          role: "Aerodynamics Engineer & Technical Coordinator",
          pic: Cala,
          pic_alt: Cala_alt,
          link: linkCala,
        }),
        TeamMember({
          name: "Leon Westra",
          role: "Aerodynamics Engineer & Development",
          pic: Leon,
          pic_alt: Leon_alt,
          link: linkLeon,
        }),
      ],
    }),

    TeamSection({
      id: "mechanics",
      preEmphasis: "The ",
      Emphasis: "/mechanics ",
      postEmphasis: "team",
      members: [
        TeamMember({
          name: "Merijn Blonk",
          role: "Mechanical Engineer",
          pic: Merijn,
          pic_alt: Merijn_alt,
          link: linkMerijn,
        }),
        TeamMember({
          name: "Sander Laskewitz",
          role: "Mechanical Engineer",
          pic: Sander,
          pic_alt: Sander_alt,
          link: linkSander,
        }),
        TeamMember({
          name: "Steven de Roos",
          role: "Mechanical Engineer",
          pic: Steven,
          pic_alt: Steven_alt,
          link: linkSteven,
        }),
        TeamMember({
          name: "Jayden de Boer",
          role: "Mechanical Engineer",
          pic: Jayden,
          pic_alt: Jayden_alt,
          link: linkJayden,
        }),
      ],
    }),

    TeamSection({
      id: "structural",
      preEmphasis: "The ",
      Emphasis: "/structural ",
      postEmphasis: "team",
      members: [
        TeamMember({
          name: "Daan Bruger",
          role: "Structural Engineer",
          pic: Daan,
          pic_alt: Daan_alt,
          link: linkDaan,
        }),
        TeamMember({
          name: "Etjen Reme",
          role: "Structural Engineer Intern",
          pic: Etjen,
          pic_alt: Etjen_alt,
          link: linkEtjen,
        }),
      ],
    }),

    TeamSection({
      id: "strategy",
      preEmphasis: "The ",
      Emphasis: "/strategy ",
      postEmphasis: "team",
      members: [
        TeamMember({
          name: "Timo Jolman",
          role: "Strategist",
          pic: Timo_J,
          pic_alt: Timo_J_alt,
          link: linkTimoJ,
        }),
        TeamMember({
          name: "Lorenzo Zambelli",
          role: "Strategist",
          pic: Lorenzo,
          pic_alt: Lorenzo_alt,
          link: linkLorenzo,
        }),
      ],
    }),

    TeamSection({
      id: "electronics",
      preEmphasis: "The ",
      Emphasis: "/electronics ",
      postEmphasis: "team",
      members: [
        TeamMember({
          name: "Yannick Meijer",
          role: "Electrical Engineer",
          pic: Yannick,
          pic_alt: Yannick_alt,
          link: linkYannick,
        }),
        TeamMember({
          name: "Rolf Kok",
          role: "Electrical Engineer",
          pic: Rolf,
          pic_alt: Rolf_alt,
          link: linkRolf,
        }),
        TeamMember({
          name: "Gratas Kanord",
          role: "Electrical Engineer",
          pic: Gratas,
          pic_alt: Gratas_alt,
          link: linkGratas,
        }),
        TeamMember({
          name: "Mohammed Sharifpour",
          role: "Electrical Engineer",
          pic: Mohammed,
          pic_alt: Mohammed_alt,
          link: linkMohammed,
        }),
      ],
    }),
  ];

  const shuffledTeamSections = teamSections.sort(() => Math.random() - 0.5);

  return (
    <>
      <div className="hero-box-25 text-center" id="parent">
        <div
          data-aos="fade-down"
          data-aos-anchor="#parent"
          data-aos-duration="1000"
          className="hero-text-bottom"
        >
          <img src={we_are_tdsr} alt="We are TDSR" effect="blur" />
        </div>
      </div>

      <div className="team-section container" data-aos="fade-up">
        <div className="row team-section-header justify-content-around">
          <h1>
            Meet our <text className="emphasis">/team</text>
          </h1>
          <h2 className="col">
            We are Top Dutch Solar Racing, a driven student team from Groningen.
            What makes us unique is the fact that we are the only team in the
            Netherlands whose members come from different levels of education
            (MBO, HBO, WO). Together we are building a solar-powered car from
            scratch with the goal of winning the Bridgestone World Solar
            Challenge.
          </h2>
        </div>
      </div>  
      <NavBar sections={sections} />
      {shuffledTeamSections.map((section, index) => (
        <React.Fragment key={index}>{section}</React.Fragment>
      ))}
      {TeamSection({
        id: "coach",
        preEmphasis: "The ",
        Emphasis: "/mentor",
        postEmphasis: "",
        members: [
          TeamMember({
            name: "Erik Westerhoff",
            role: "Team mentor",
            pic: Erik,
            pic_alt: Erik_alt,
            link: linkErik,
          }),
        ],
      })}
    </>
  );
}

export default Teams;
