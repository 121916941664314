import "./Teams.scss";
import React from "react";
import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";

// import team pics
import Erik_Westerhoff from "../../Resources/Pics/team_photos_19/Erik_Westerhoff.webp";
import Ruben_Wegstapel from "../../Resources/Pics/team_photos_19/Ruben_Wegstapel.webp";
import Jussi_Vuopionperä from "../../Resources/Pics/team_photos_19/Jussi_Vuopionpera.webp";
import Rein_Rings from "../../Resources/Pics/team_photos_19/Rein_Rings.webp";
import Olga_Korosteleva from "../../Resources/Pics/team_photos_19/Olga_Korosteleva.webp";
import Margot_van_der_Haar from "../../Resources/Pics/team_photos_19/Margot_van_der_Haar.webp";
import Jeroen_Brattinga from "../../Resources/Pics/team_photos_19/Jeroen_Brattinga.webp";
import Martijn_Zonjee from "../../Resources/Pics/team_photos_19/Martijn_Zonjee.webp";
import Eldert_Zeinstra from "../../Resources/Pics/team_photos_19/Eldert_Zeinstra.webp";
import Frank_Pot from "../../Resources/Pics/team_photos_19/Frank_Pot.webp";
import Kevin_Luinge from "../../Resources/Pics/team_photos_19/Kevin_Luinge.webp";
import Ray_Blokker from "../../Resources/Pics/team_photos_19/Ray_Blokker.webp";
import Hessel_de_Groot from "../../Resources/Pics/team_photos_19/Hessel_de_Groot.webp";
import Vincent_Taselaar from "../../Resources/Pics/team_photos_19/Vincent_Taselaar.webp";
import Friso_Resink from "../../Resources/Pics/team_photos_19/Friso_Resink.webp";
import Tjitte_van_der_Ploeg from "../../Resources/Pics/team_photos_19/Tjitte_van_der_Ploeg.webp";
import Ruben_Otter from "../../Resources/Pics/team_photos_19/Ruben_Otter.webp";
import Meindert_de_Groot from "../../Resources/Pics/team_photos_19/Meindert_de_Groot.webp";
import Nando_Bongers from "../../Resources/Pics/team_photos_19/Nando_Bongers.webp";
import Tim_de_Vries from "../../Resources/Pics/team_photos_19/Tim_de_Vries.webp";
import Job_Verkruisen from "../../Resources/Pics/team_photos_19/Job_Verkruisen.webp";
import Sarah_Carelsz from "../../Resources/Pics/team_photos_19/Sarah_Carelsz.webp";
import Maria_Lanza_Arancibia from "../../Resources/Pics/team_photos_19/Maria_Lanza_Arancibia.webp";
import Nicole_Sanders from "../../Resources/Pics/team_photos_19/Nicole_Sanders.webp";
import Jacqueline_Pushchin from "../../Resources/Pics/team_photos_19/Jacqueline_Pushchin.webp";
import Eline_Hesta from "../../Resources/Pics/team_photos_19/Eline_Hesta.webp";
import Ben_Heerspink from "../../Resources/Pics/team_photos_19/Ben_Heerspink.webp";
import we_are_tdsr from "../../Resources/Pics/team_photos_19/we_are.svg";
// get team linkedin links


function NavBar({ sections }) {
  const scrollToSection = (id) => {
    const element = document.getElementById(id);
    const yOffset = -200; // Adjust this value as needed
    const yPosition = element.getBoundingClientRect().top + window.pageYOffset + yOffset;
    
    window.scrollTo({
      top: yPosition,
      behavior: "smooth",
    });
  };
  return (
    
    <div className="team-section container">
      <div className="row team-section-header justify-content-around">
        <h1>
          {"Find us with a "}
          <text className="emphasis">{"/click"} </text>
        </h1>
      </div>
      <div className="team-member">
        {sections.map((section) => (
          <button
            key={section.id}
            onClick={() => scrollToSection(section.id)}
            className="teamOp-button"
            >
            {section.name}
          </button>
        
        ))}
      </div>
    </div>
  );
}
// function to create team member
function TeamMember(props) {
  return (
    <div className="team-member">
      <a href={props.link} target="_blank" rel="noreferrer">
        <img
          src={props.pic}
          placeholderSrc={props.pic_alt}
          alt={props.name}
          className="img-fluid member-pic"
          effect="blur"
        />
        <h3>{props.name}</h3>
      </a>
      <p>{props.role}</p>
    </div>
  );
}

// function to create team section with unknown number of children
function TeamSection(props) {
  return (
    <div id={props.id} className="team-section container" data-aos="fade-up">
      <div className="row team-section-header justify-content-around">
        <h1>
          {props.preEmphasis}
          <text className="emphasis">{props.Emphasis}</text>
          {props.postEmphasis}
        </h1>
        {/* loop trough the children prop */}
        {props.members.map((child, index) => {
          return (
            <div className="col-md-6" key={index}>
              {child}
            </div>
          );
        })}
      </div>
    </div>
  );
}


function Teams() {
  const sections = [
    { id: "organizational", name: "Organizational Team" },
    { id: "electronics", name: "Electronics Team" },
    { id: "mechanics", name: "Mechanics Team" },
    { id: "communication", name: "Communication, Media & Events Team" },
  ];
  const teamSections = [
    TeamSection({
      id: "organizational",
      preEmphasis: "The ",
      Emphasis: "/organizational ",
      postEmphasis: "team",
      members: [
        TeamMember({
          name: "Jeroen Brattinga",
          role: "Team Lead and Founder",
          pic: Jeroen_Brattinga,
        }),
        TeamMember({
          name: "Olga Korosteleva",
          role: "Team Facilitator",
          pic: Olga_Korosteleva,
        }),

        TeamMember({
          name: "Jussi Vuopionperä",
          role: "Operations and Sponsorship",
          pic: Jussi_Vuopionperä,
        }),
        TeamMember({
          name: "Margot van der Haar",
          role: "Travel Organization Lead",
          pic: Margot_van_der_Haar,
        }),
        TeamMember({
          name: "Rein Rings",
          role: "Travel Coordinator",
          pic: Rein_Rings,
        }),
        TeamMember({
          name: "Ruben Wegstapel",
          role: "Travel Coordinator",
          pic: Ruben_Wegstapel,
        }),
        TeamMember({
          name: "Erik Westerhoff",
          role: "Team Coach",
          pic: Erik_Westerhoff,
        }),
      ],
    }),
    TeamSection({
      id: "mechanics",
      preEmphasis: "The ",
      Emphasis: "/mechanics ",
      postEmphasis: "team",
      members: [
        TeamMember({
          name: "Ray Blokker",
          role: "Mechanical Lead",
          pic: Ray_Blokker,
        }),
        TeamMember({
          name: "Hessel de Groot",
          role: "Mechanical Engineer",
          pic: Hessel_de_Groot,
        }),
        TeamMember({
          name: "Kevin Luinge",
          role: "Mechanical Engineer and Driver",
          pic: Kevin_Luinge,
        }),
        TeamMember({
          name: "Frank Pot",
          role: "Aerodynamics Engineer and Founder",
          pic: Frank_Pot,
        }),
        TeamMember({
          name: "Eldert Zeinstra",
          role: "Mechanical Engineer and Founder",
          pic: Eldert_Zeinstra,
        }),
        TeamMember({
          name: "Martijn Zonjee",
          role: "Mechanical Engineer and Driver",
          pic: Martijn_Zonjee,
        }),
      ],
    }),

    TeamSection({
      id: "electronics",
      preEmphasis: "The ",
      Emphasis: "/electronics ",
      postEmphasis: "team",
      members: [
        TeamMember({
          name: "Tijtte van der Ploeg",
          role: "Electronics Lead",
          pic: Tjitte_van_der_Ploeg,
        }),
        TeamMember({
          name: "Meindert de Groot",
          role: "Electrical Engineer",
          pic: Meindert_de_Groot,
        }),
        TeamMember({
          name: "Vincent Taselaar",
          role: "Electrical Engineer, Driver and Founder",
          pic: Vincent_Taselaar,
        }),
        TeamMember({
          name: "Nando Bongers",
          role: "Software Engineer",
          pic: Nando_Bongers,
        }),
        TeamMember({
          name: "Ruben Otter",
          role: "Software Engineer and Driver",
          pic: Ruben_Otter,
        }),
        TeamMember({
          name: "Friso Resink",
          role: "Strategy",
          pic: Friso_Resink,
        }),
      ],
    }),

    TeamSection({
      id: "communication",
      preEmphasis: "The ",
      Emphasis: "/communication, media & events ",
      postEmphasis: "team",
      members: [
        TeamMember({
          name: "Eline Hesta",
          role: "Communication Lead",
          pic: Eline_Hesta,
        }),
        TeamMember({
          name: "Sarah Carelsz",
          role: "Graphic Designer",
          pic: Sarah_Carelsz,
        }),
        TeamMember({
          name: "Nicole Sanders",
          role: "Graphic Designer",
          pic: Nicole_Sanders,
        }),
        TeamMember({
          name: "Maria Lanza Arancibia",
          role: "Content Creator",
          pic: Maria_Lanza_Arancibia,
        }),

        TeamMember({
          name: "Jacqueline Puschin",
          role: "Content Creator",
          pic: Jacqueline_Pushchin,
        }),

        TeamMember({
          name: "Job Verkruisen",
          role: "Photographer",
          pic: Job_Verkruisen,
        }),

        TeamMember({
          name: "Ben Heerspink",
          role: "Film Maker",
          pic: Ben_Heerspink,
        }),

        TeamMember({
          name: "Tim de Vries",
          role: "Film Maker",
          pic: Tim_de_Vries,
        }),
      ],
    })
  ]
  const shuffledTeamSections = teamSections.sort(() => Math.random() - 0.5);
  return (
    <>
      <div className="hero-box-19 text-center" id="parent">
        <div
          data-aos="fade-down"
          data-aos-anchor="#parent"
          data-aos-duration="1000"
          className="hero-text-bottom"
        >
          <img src={we_are_tdsr} alt="We are TDSR" effect="blur" />
        </div>
      </div>

      <div className="team-section container" data-aos="fade-up">
        <div className="row team-section-header justify-content-around">
          <h1>
            Meet our <text className="emphasis">/team</text>
          </h1>
          <h2 className="col">
            Team 2019 was the first team of Top Dutch Solar Racing. With 26
            members strong, from over 10 educational programs, they are the
            foundation of TDSR.
          </h2>
        </div>
      </div>
      <NavBar sections={sections} />
      {shuffledTeamSections.map((section, index) => (
        <React.Fragment key={index}>{section}</React.Fragment>
      ))}
    </>
  );
}

export default Teams;
