function PrivacyPolicy() {
  return (
    <div className="container simple-section">
      <div className="row simple-section-header">
        <h1>Privacy Policy</h1>
      </div>
      <div className="row simple-section-body">
        <div className="col-12">
          <p>
            Stichting Solar Racing Groningen, gevestigd aan Zernikelaan 17, 9747
            AA Groningen, is verantwoordelijk voor de verwerking van
            persoonsgegevens zoals weergegeven in deze privacyverklaring.
            Contactgegevens: https://solarracing.nl/ Zernikelaan 17, 9747 AA
            Groningen +31640890154{" "}
            <h1> Persoonsgegevens die wij verwerken Stichting</h1>
            Solar Racing Groningen verwerkt uw persoonsgegevens doordat u
            gebruik maakt van onze diensten en/of omdat u deze zelf aan ons
            verstrekt. Hieronder vindt u een overzicht van de persoonsgegevens
            die wij verwerken: - Voor- en achternaam - Adresgegevens -
            Telefoonnummer - E-mailadres{" "}
            <h1>
              {" "}
              Bijzondere en/of gevoelige persoonsgegevens die wij verwerken
            </h1>{" "}
            Onze website en/of dienst heeft niet de intentie gegevens te
            verzamelen over websitebezoekers die jonger zijn dan 16 jaar. Tenzij
            ze toestemming hebben van ouders of voogd. We kunnen echter niet
            controleren of een bezoeker ouder dan 16 is. Wij raden ouders dan
            ook aan betrokken te zijn bij de online activiteiten van hun
            kinderen, om zo te voorkomen dat er gegevens over kinderen verzameld
            worden zonder ouderlijke toestemming. Als u er van overtuigd bent
            dat wij zonder die toestemming persoonlijke gegevens hebben
            verzameld over een minderjarige, neem dan contact met ons op via
            info@solarracinggroningen.nl, dan verwijderen wij deze informatie.{" "}
            <h1>
              Met welk doel en op basis van welke grondslag wij persoonsgegevens
              verwerken
            </h1>{" "}
            Stichting Solar Racing Groningen verwerkt uw persoonsgegevens voor
            de volgende doelen: - Het afhandelen van uw betaling - Verzenden van
            onze nieuwsbrief en/of reclamefolder - U te kunnen bellen of
            e-mailen indien dit nodig is om onze dienstverlening uit te kunnen
            voeren - Om goederen en diensten bij u af te leveren
            <h1>Hoe lang we persoonsgegevens bewaren</h1>
            Stichting Solar Racing Groningen bewaart uw persoonsgegevens niet
            langer dan strikt nodig is om de doelen te realiseren waarvoor uw
            gegevens worden verzameld. Wij hanteren de volgende bewaartermijnen
            voor de volgende (categorieën) van persoonsgegevens:
            5 jaar <h1>
              Delen van persoonsgegevens met derden
            </h1>{" "}
            Stichting Solar Racing Groningen verkoopt uw gegevens niet aan
            derden en verstrekt deze uitsluitend indien dit nodig is voor de
            uitvoering van onze overeenkomst met u of om te voldoen aan een
            wettelijke verplichting. Met bedrijven die uw gegevens verwerken in
            onze opdracht, sluiten wij een bewerkersovereenkomst om te zorgen
            voor eenzelfde niveau van beveiliging en vertrouwelijkheid van uw
            gegevens. Stichting Solar Racing Groningen blijft verantwoordelijk
            voor deze verwerkingen.
            <h1>
              {" "}
              Cookies, of vergelijkbare technieken, die wij gebruiken
            </h1>{" "}
            Stichting Solar Racing Groningen gebruikt alleen technische en
            functionele cookies. En analytische cookies die geen inbreuk maken
            op uw privacy. Een cookie is een klein tekstbestand dat bij het
            eerste bezoek aan deze website wordt opgeslagen op uw computer,
            tablet of smartphone. De cookies die wij gebruiken zijn noodzakelijk
            voor de technische werking van de website en uw gebruiksgemak. Ze
            zorgen ervoor dat de website naar behoren werkt en onthouden
            bijvoorbeeld uw voorkeursinstellingen. Ook kunnen wij hiermee onze
            website optimaliseren. U kunt zich afmelden voor cookies door uw
            internetbrowser zo in te stellen dat deze geen cookies meer opslaat.
            Daarnaast kunt u ook alle informatie die eerder is opgeslagen via de
            instellingen van uw browser verwijderen.{" "}
            <h1>Gegevens inzien, aanpassen of verwijderen</h1> U heeft het recht
            om uw persoonsgegevens in te zien, te corrigeren of te verwijderen.
            Daarnaast heeft u het recht om uw eventuele toestemming voor de
            gegevensverwerking in te trekken of bezwaar te maken tegen de
            verwerking van uw persoonsgegevens door Stichting Solar Racing
            Groningen en heeft u het recht op gegevensoverdraagbaarheid. Dat
            betekent dat u bij ons een verzoek kunt indienen om de
            persoonsgegevens die wij van u beschikken in een computerbestand
            naar u of een ander, door u genoemde organisatie, te sturen. U kunt
            een verzoek tot inzage, correctie, verwijdering, gegevensoverdraging
            van uw persoonsgegevens of verzoek tot intrekking van uw toestemming
            of bezwaar op de verwerking van uw persoonsgegevens sturen naar
            info@solarracinggroningen.nl. Om er zeker van te zijn dat het
            verzoek tot inzage door u is gedaan, vragen wij u een kopie van uw
            identiteitsbewijs met het verzoek mee te sturen. Maak in deze kopie
            uw pasfoto, MRZ (machine readable zone, de strook met nummers
            onderaan het paspoort), paspoortnummer en Burgerservicenummer (BSN)
            zwart. Dit ter bescherming van uw privacy. We reageren zo snel
            mogelijk, maar binnen vier weken, op uw verzoek. Stichting Solar
            Racing Groningen wil u er tevens op wijzen dat u de mogelijkheid
            heeft om een klacht in te dienen bij de nationale toezichthouder, de
            Autoriteit Persoonsgegevens. Dat kan via de volgende link:
            https://autoriteitpersoonsgegevens.nl/nl/contact-met-de-autoriteit-persoonsgegevens/tip-ons
            <h1>
              Hoe wij persoonsgegevens beveiligen Stichting Solar Racing
              Groningen{" "}
            </h1>
            neemt de bescherming van uw gegevens serieus en neemt passende
            maatregelen om misbruik, verlies, onbevoegde toegang, ongewenste
            openbaarmaking en ongeoorloofde wijziging tegen te gaan. Als u de
            indruk heeft dat uw gegevens niet goed beveiligd zijn of er
            aanwijzingen zijn van misbruik, neem dan contact op met onze
            klantenservice of via info@solarracinggroningen.nl
          </p>
        </div>
      </div>
    </div>
  );
}

export default PrivacyPolicy;
