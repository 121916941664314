import React from "react";
import "./Contact.scss";

let become = "become@solarracinggroningen.nl";
let acquisition = "acquisition@solarracinggroningen.nl";
let comms = "pers@solarracinggroningen.nl";
let info = "info@solarracinggroningen.nl";
let bestuur = "bestuur@solarracinggroningen.nl";

const Contact = () => {
  const duration = 1000;

  return (
    <div>
      <div
        data-aos="fade-left"
        data-aos-duration={duration}
        className="container simple-section mt-5"
      >
        <div className="row simple-section-header">
          <h1>
            <text className="emphasis">/TDSR</text> Contact Details
          </h1>
        </div>
        <div className="row simple-section-body">
          <div className="col-md-6 order-sm-first pb-5">
            <div className="contact-special overflow-hidden">
              <h2>Emails</h2>
              <h5>
                If you want further information about our team or if you have
                questions:
              </h5>
              
              <a className="mail" href="mailto:info@solarracinggroningen.nl">
                <text className="emphasis"> /Information</text> {info}
              </a>
              
              <h5 className="pt-3">
              If you want reach out to the Bestuur:
              </h5>
              <a
                className=" mail"
                href="mailto:bestuur@solarracinggroningen.nl"
              >
                <text className="emphasis"> /Bestuur</text> {bestuur}
              </a>

              <h5 className="pt-3">
                If you want to get in touch with our Communication department:
              </h5>
              <a className="mail" href="mailto:pers@solarracinggroningen.nl">
                <text className="emphasis"> /Communication</text> {comms}
              </a>

              <h5 className="pt-3">
                If you want to contact us for business purposes:
              </h5>
              <a
                className="mail"
                href="mailto:acquisition@solarracinggroningen.nl"
              >
                <text className="emphasis">/Acquisition</text> {acquisition}
              </a>

              <h5 className="pt-3">
                If you want to apply for a position within our team:
              </h5>
              <a className=" mail" href="mailto:become@solarracinggroningen.nl">
                <text className="emphasis">/Become</text> {become}
              </a>
            </div>
          </div>
          <div className="col-md-6 order-sm-last">
            <div class="mapouter">
              <div class="gmap_canvas">
                <iframe
                  width="100%"
                  height="400"
                  id="gmap_canvas"
                  src="https://maps.google.com/maps?q=Zernikelaan%2017,%209747%20AA&t=&z=13&ie=UTF8&iwloc=&output=embed"
                  frameborder="0"
                  scrolling="no"
                  marginheight="0"
                  marginwidth="0"
                ></iframe>
                <a href="https://www.embedgooglemap.net/blog/divi-discount-code-elegant-themes-coupon/"></a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Contact;
