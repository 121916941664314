import React from "react";

// Define styles for FAQ items and paragraphs
const faqItemStyle = {
  marginBottom: "2rem", // Increase space between questions
  marginLeft: "1rem", // Add some margin to the left
  marginRight: "1rem", // Add some margin to the right
};

const paragraphStyle = {
  textAlign: "justify", // Justify the content
  marginLeft: "1rem", // Add some margin to the left
  marginRight: "1rem", // Add some margin to the right
};

const centeredStyle = {
  display: "flex",
  justifyContent: "center",
  alignItems: "center", // Optional if you need vertical centering
};

function FAQ() {
  return (
    <div>
      <div className="container simple-section mt-5 pb-5">
        <div style={centeredStyle}>
          <h1 className="pt-3 mb-5">Frequently Asked Questions</h1>
        </div>
        <div style={faqItemStyle}>
          <h3>How long does the project take?</h3>
          <p style={paragraphStyle}>
            Approximately 1.5 years. June 2024 - February 2026. The new team
            will start around June 2024 with some activities and starting to get
            to know the project, from September on you are expected to work
            full-time till November 2025. Till February 2026 it is expected to
            still spend some time for TDSR as things like recruiting the new
            team, writing documentation, etc. still need to be done!
          </p>
        </div>
        <div style={faqItemStyle}>
          <h3>Do you get paid?</h3>
          <p style={paragraphStyle}>
            This project is on a voluntary basis, so you won't receive any
            money. However, the expenses for around 2 months in Australia will
            be covered (flights, accommodation, food) by us. Furthermore, the
            experience, skills, and friendships you make along the way are more
            valuable than any payment!
          </p>
        </div>
        <div style={faqItemStyle}>
          <h3>Is it full-time or part-time?</h3>
          <p style={paragraphStyle}>
            Being part of TDSR means that you will have to work on a full-time
            basis. At least from September 2024 till November 2025.
          </p>
        </div>
        <div style={faqItemStyle}>
          <h3>Can I get credits?</h3>
          <p style={paragraphStyle}>
            This highly depends on your study. In some cases you can combine
            your minor, internship or graduation assignment with TDSR, however,
            you are required (and you will want to) to work on a full-time basis
            for TDSR, so you have to plan it well with your study advisor.
          </p>
        </div>
        <div style={faqItemStyle}>
          <h3>Will my expenses for Australia be paid?</h3>
          <p style={paragraphStyle}>
            That depends on how your team acquires the money needed. In the past
            all the teams got all the expenses for Australia covered, so we
            assume that this will be the case again.
          </p>
        </div>
        <div style={faqItemStyle}>
          <h3>How will my time at TDSR look like?</h3>
          <p style={paragraphStyle}>
            That highly depends on your subgroup and the goals you set within
            the team. However, we can give you a general timeline:
            <ul>
              <li>
                June 2024 - September 2024: Start of your time at TDSR, get to
                know the team, start planning with your sub-group
              </li>
              <li>
                September 2024: Latest now your full-time commitment is asked
                for. At the end of the month, you will participate in the ilumen
                European Solar Challenge in Belgium, your first contact with
                solar racing. This is an endurance race with multiple teams in
                Europe.
              </li>
              <li>
                October 2024 - February 2025: Design phase of your solar car.
              </li>
              <li>
                March 2025 - August 2025: Manufacturing and testing phase.
              </li>
              <li>
                September 2025 - November 2025: Australia and participation at
                the Bridgestone World Solar Challenge.
              </li>
              <li>December 2025 - February 2026: After Australia work.</li>
            </ul>
          </p>
        </div>
        <div style={faqItemStyle}>
          <h3>Why should I join TDSR?</h3>
          <p style={paragraphStyle}>
            You will be on the journey of your lifetime! You have the chance to
            put your theoretical knowledge into practice. You can develop
            yourself on a personal and professional level by acquiring new
            skills. You’ll have to manage your own tasks and contribute to
            building your very own solar car. Furthermore, you’ll be working in
            a multidisciplinary and international environment which allows you
            to look outside the box of your study and get to know how to work
            with different people.
          </p>
          <p style={paragraphStyle}>
            You’ll also get in contact with our amazing partners which will
            allow you to broaden your network and might give you opportunities
            for the future. Moreover, you will be part of something not everyone
            does, that challenges yourself and differentiates you from other
            students.
          </p>
        </div>
        <div style={faqItemStyle}>
          <h3>Additional Information</h3>
          <p style={paragraphStyle}>
            As a member of Top Dutch Solar Racing, there is the possibility to
            get an exemption from paying college tuition while still keeping the
            benefits of being a student. Arrangements have been made with CUOS
            in which we get a certain amount of money annualy to pay for team
            members'college tuition. However, there are limitations regarding
            this:
          </p>
          <ul>
            <li>
              A full refund could be provided if you earn no credits over the
              full year.
            </li>
            <li>
              Half a refund could be provided if you either get credits in the
              first or the second semester.
            </li>
            <li>
              No refund will be provided if you get credits throughout the whole
              year.
            </li>
          </ul>
          <p style={paragraphStyle}>
            Depending on how many people want their college tuition back, the
            amount could vary because we have a limit.
          </p>
          <p style={paragraphStyle}>Each case will be handled individually.</p>
        </div>
      </div>
    </div>
  );
}

export default FAQ;
