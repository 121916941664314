import "./ComingSoon.scss";
import { Link } from "react-router-dom";

function ComingSoon(props) {
  return (
    <>
      {" "}
      <div className="container simple-section">
        <div className="row simple-section-header ">
          {/* if the props.error is true render an error message otherwise error a coming soon message */}
          <h1>{props.error ? "Error 404" : "Coming Soon"}</h1>
          <div className="col d-flex flex-column justify-content-center align-items-center">
            <p>
              {props.error
                ? "Looks like this is not a valid page!"
                : "Looks like this page is under construction. Please come back later!"}
            </p>
            <Link to="/">
              <button className="btn btn-primary-green">Home</button>
            </Link>
          </div>
        </div>
      </div>
    </>
  );
}

export default ComingSoon;
