function TermsAndConditionsNL() {
  return (
    <div className="container simple-section">
      <div className="row simple-section-header">
        <h1>
          Algemene voorwaarden voor de crowdfundingcampagne van Stichting Solar
          Racing Groningen
        </h1>
      </div>
      <div className="row simple-section-body">
        <div className="col-12">
          <p>
            <h3>Donaties:</h3> Alle donaties aan de crowdfundingcampagne van
            Stichting Solar Racing Groningen zijn vrijwillig en niet
            terugbetaalbaar. De non-profitorganisatie die de campagne runt, is
            niet verplicht om enige goederen of diensten te leveren in ruil voor
            donaties.
            <h3> Gebruik van de opbrengst:</h3> De opbrengst van de donaties zal
            uitsluitend worden gebruikt ter ondersteuning van de logistieke
            aspecten van het project en de ontwikkeling en onderzoek van
            technologie. Stichting Solar Racing Groningen zal zich inspannen om
            ervoor te zorgen dat de fondsen effectief en efficiënt worden
            gebruikt voor het aangegeven doel.
            <h3>Openbaarmaking:</h3> De donateurs erkennen dat hun donatiebedrag
            zal worden geplaatst op de website solarfunds.nl als openbaarmaking.
            De naam en e-mailadres van de donor kunnen ook op de website worden
            weergegeven, tenzij de donor anders verzoekt.
            <h3>Belastingaftrek:</h3>Donaties aan Stichting Solar Racing
            Groningen kunnen aftrekbaar zijn volgens de toepasselijke
            belastingwetgeving. Het is echter de verantwoordelijkheid van de
            donor om een ​​belastingdeskundige te raadplegen over de
            belastingimplicaties van hun donatie.
            <h3>Privacy:</h3> Stichting Solar Racing Groningen zal persoonlijke
            informatie van donateurs verzamelen en gebruiken uitsluitend voor
            het verwerken van hun donatie en om hen op de hoogte te houden van
            de campagne. De non-profitorganisatie zal geen donorgegevens delen
            of verkopen aan derden.
            <h3>Aansprakelijkheid: </h3> Stichting Solar Racing Groningen is
            niet aansprakelijk voor enige schade of verliezen die voortvloeien
            uit het gebruik van de door de donateurs gedoneerde fondsen.
            Stichting Solar Racing Groningen is niet aansprakelijk voor
            technische storingen of problemen die zich kunnen voordoen tijdens
            de crowdfundingcampagne.
            <h3>Wijzigingen:</h3>
            Stichting Solar Racing Groningen behoudt zich het recht voor om deze
            algemene voorwaarden op elk moment te wijzigen of bij te werken.
            Eventuele wijzigingen in de algemene voorwaarden zijn onmiddellijk
            van kracht na plaatsing.
            <h3>Toepasselijk recht:</h3> Deze algemene voorwaarden worden
            beheerst door en geïnterpreteerd in overeenstemming met de wetten
            van Nederland.
            <br></br>
            Door te doneren aan de crowdfundingcampagne, stemmen donateurs in
            met deze algemene voorwaarden. Als u vragen heeft over deze algemene
            voorwaarden of de crowdfundingcampagne, neem dan contact met ons op
            via info@solarracinggroningen.nl.
          </p>
        </div>
      </div>
    </div>
  );
}

export default TermsAndConditionsNL;
