import "./Teams.scss";
import React from "react";
import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";

// import team pics
import Aymar from "../../Resources/Pics/team_photos_21/Aymar_Berkel.webp";
import Calvin from "../../Resources/Pics/team_photos_21/Calvin_Beijering.webp";
import Iris from "../../Resources/Pics/team_photos_21/Iris_Kok.webp";
import Alje from "../../Resources/Pics/team_photos_21/Alje_Zwijghuizen.webp";
import Caroline from "../../Resources/Pics/team_photos_21/Caroline_Nauta.webp";
import Harjan from "../../Resources/Pics/team_photos_21/Harjan_Offereins.webp";
import Jamie from "../../Resources/Pics/team_photos_21/Jamie_Jankowsky.webp";
import Anas from "../../Resources/Pics/team_photos_21/Anas_Khatter.webp";
import Bernd from "../../Resources/Pics/team_photos_21/Bernd_Geertsema.webp";
import Caitlin from "../../Resources/Pics/team_photos_21/Caitlin_de_Boer.webp";
import Erik from "../../Resources/Pics/team_photos_21/Erik.webp";
import Johannes from "../../Resources/Pics/team_photos_21/Johannes_Wilkens.webp";
import Jolijn from "../../Resources/Pics/team_photos_21/Jolijn_Visser.webp";
import Koen from "../../Resources/Pics/team_photos_21/Koen_Wortelboer.webp";
import Maurice from "../../Resources/Pics/team_photos_21/Maurice_Stoffijn.webp";
import Max from "../../Resources/Pics/team_photos_21/Max_Reuter.webp";
import Meike from "../../Resources/Pics/team_photos_21/Meike_Krumpelman.webp";
import Patrick from "../../Resources/Pics/team_photos_21/Patrick_Postma.webp";
import Peter from "../../Resources/Pics/team_photos_21/Peter_Berkel.webp";
import Remmelt from "../../Resources/Pics/team_photos_21/Remmelt_Hofstra.webp";
import Rick from "../../Resources/Pics/team_photos_21/Rick_Geling.webp";
import Ruben from "../../Resources/Pics/team_photos_21/Ruben_Joosten.webp";
import Sander from "../../Resources/Pics/team_photos_21/Sander_Bouma.webp";
import Timo from "../../Resources/Pics/team_photos_21/Timo_Kiuzenga.webp";
import Veronika from "../../Resources/Pics/team_photos_21/Veronika_Tajgler.webp";
import we_are_tdsr from "../../Resources/Pics/team_photos_21/we_are.svg";
// get team linkedin links

function NavBar({ sections }) {
  const scrollToSection = (id) => {
    const element = document.getElementById(id);
    const yOffset = -200; // Adjust this value as needed
    const yPosition = element.getBoundingClientRect().top + window.pageYOffset + yOffset;
    
    window.scrollTo({
      top: yPosition,
      behavior: "smooth",
    });
  };
  return (
    
    <div className="team-section container">
      <div className="row team-section-header justify-content-around">
        <h1>
          {"Find us with a "}
          <text className="emphasis">{"/click"} </text>
        </h1>
      </div>
      <div className="team-member">
        {sections.map((section) => (
          <button
            key={section.id}
            onClick={() => scrollToSection(section.id)}
            className="teamOp-button"
            >
            {section.name}
          </button>
        
        ))}
      </div>
    </div>
  );
}

// function to create team member
function TeamMember(props) {
  return (
    <div className="team-member">
      <a href={props.link} target="_blank" rel="noreferrer">
        <img
          src={props.pic}
          placeholderSrc={props.pic_alt}
          alt={props.name}
          className="img-fluid member-pic"
          effect="blur"
        />
        <h3>{props.name}</h3>
      </a>
      <p>{props.role}</p>
    </div>
  );
}

// function to create team section with unknown number of children
function TeamSection(props) {
  return (
    <div id={props.id} className="team-section container" data-aos="fade-up">
      <div className="row team-section-header justify-content-around">
        <h1>
          {props.preEmphasis}
          <text className="emphasis">{props.Emphasis}</text>
          {props.postEmphasis}
        </h1>
        {/* loop trough the children prop */}
        {props.members.map((child, index) => {
          return (
            <div className="col-md-6" key={index}>
              {child}
            </div>
          );
        })}
      </div>
    </div>
  );
}

function Teams() {
  const sections = [
    { id: "management", name: "Management Team" },
    { id: "software", name: "Software Team" },
    { id: "logistics", name: "Travel Logistics Team" },
    { id: "communication", name: "Communication, Media & Events Team" },
    { id: "acquisitions", name: "Acquisition & Organization Team" },
    { id: "mechanics", name: "Mechanics Team" },
    { id: "strategy", name: "Strategy Team" },
    { id: "electronics", name: "Electronics Team" },
    { id: "coach", name: "Team coach" },
  ];
  const teamSections = [
    TeamSection({
      id: "management",
      preEmphasis: "The ",
      Emphasis: "/management ",
      postEmphasis: "team",
      members: [
        TeamMember({
          name: "Aymar Berkel",
          role: "Team Manager",
          pic: Aymar,
        }),
        TeamMember({
          name: "Calvin Beijering",
          role: "Project Manager",
          pic: Calvin,
        }),
        TeamMember({
          name: "Anas Khatter",
          role: "Technical Manager and Mechanical Engineer",
          pic: Anas,
        }),
      ],
    }),
    TeamSection({
      id: "acquisitions",
      preEmphasis: "The ",
      Emphasis: "/acquisition & organization ",
      postEmphasis: "team",
      members: [
        TeamMember({
          name: "Veronika Tajgler",
          role: "Acquisitions",
          pic: Veronika,
        }),
        TeamMember({
          name: "Iris Kok",
          role: "Acquisitions",
          pic: Iris,
        }),
      ],
    }),

    TeamSection({
      id: "logistics",
      preEmphasis: "The ",
      Emphasis: "/travel logistics ",
      postEmphasis: "team",
      members: [
        TeamMember({
          name: "Jolijn Visser",
          role: "Travel Logistics",
          pic: Jolijn,
        }),
        TeamMember({
          name: "Maurice Stoffijn",
          role: "Travel Logistics",
          pic: Maurice,
        }),
        TeamMember({
          name: "Meike Krumpelman",
          role: "Travel Logistics",
          pic: Meike,
        }),
      ],
    }),

    TeamSection({
      id: "communication",
      preEmphasis: "The ",
      Emphasis: "/communication, media & events ",
      postEmphasis: "team",
      members: [
        TeamMember({
          name: "Alje Zwijghuizen",
          role: "Creative Lead",
          pic: Alje,
        }),
        TeamMember({
          name: "Caroline Nauta",
          role: "Communication Group Lead and Graphic Design",
          pic: Caroline,
        }),
        TeamMember({
          name: "Jamie Jankowsky",
          role: "Media and PR",
          pic: Jamie,
        }),
      ],
    }),
    TeamSection({
      id: "mechanics",
      preEmphasis: "The ",
      Emphasis: "/mechanics ",
      postEmphasis: "team",
      members: [
        TeamMember({
          name: "Ruben Joosten",
          role: "Mechanical Lead",
          pic: Ruben,
        }),
        TeamMember({
          name: "Bernd Geertsema",
          role: "Mechanical Engineer",
          pic: Bernd,
        }),
        TeamMember({
          name: "Max Reuter",
          role: "Mechanical Engineer",
          pic: Max,
        }),
        TeamMember({
          name: "Remmelt Hofstra",
          role: "Mechanical Engineer",
          pic: Remmelt,
        }),
      ],
    }),

    TeamSection({
      id: "electronics",
      preEmphasis: "The ",
      Emphasis: "/electronics ",
      postEmphasis: "team",
      members: [
        TeamMember({
          name: "Sander Bouma",
          role: "Electrical Engineer",
          pic: Sander,
        }),
        TeamMember({
          name: "Patrick Postma",
          role: "Electrical Engineer",
          pic: Patrick,
        }),
        TeamMember({
          name: "Johannes Wilkens",
          role: "Electrical Engineer",
          pic: Johannes,
        }),
        TeamMember({
          name: "Timo Kuizenga",
          role: "Electrical Engineer",
          pic: Timo,
        }),
      ],
    }),

    TeamSection({
      id: "strategy",
      preEmphasis: "The ",
      Emphasis: "/strategy ",
      postEmphasis: "team",
      members: [
        TeamMember({
          name: "Koen Wortelboer",
          role: "Strategist",
          pic: Koen,
        }),
        TeamMember({
          name: "Rick Geling",
          role: "Strategist",
          pic: Rick,
        }),
      ],
    }),

    TeamSection({
      id: "software",
      preEmphasis: "The ",
      Emphasis: "/software ",
      postEmphasis: "team",
      members: [
        TeamMember({
          name: "Caitlin de Boer",
          role: "Software Engineer",
          pic: Caitlin,
        }),
      ],
    })
  ]
  const shuffledTeamSections = teamSections.sort(() => Math.random() - 0.5);
  return (
    <>
      <div className="hero-box-21 text-center" id="parent">
        <div
          data-aos="fade-down"
          data-aos-anchor="#parent"
          data-aos-duration="1000"
          className="hero-text-bottom"
        >
          <img src={we_are_tdsr} alt="We are TDSR" effect="blur" />
        </div>
      </div>

      <div className="team-section container" data-aos="fade-up">
        <div className="row team-section-header justify-content-around">
          <h1>
            Meet our <text className="emphasis">/team</text>
          </h1>
          <h2 className="col">
            Team 2021 consists of 24 students, from secondary vocational
            education (MBO), higher professional education (HBO) and university
            education (WO). Every individual has a unique contribution that
            makes our project a success. Our team members follow over 10
            different educational programs and either help with the technical,
            organizational or communicational tasks.
          </h2>
        </div>
      </div>
      <NavBar sections={sections} />
      {shuffledTeamSections.map((section, index) => (
        <React.Fragment key={index}>{section}</React.Fragment>
      ))}
      {TeamSection({
        id: "coach",
        preEmphasis: "The ",
        Emphasis: "/team coach",
        postEmphasis: "",
        members: [
          TeamMember({
            name: "Erik Westerhoff",
            role: "Team mentor",
            pic: Erik,
          }),
        ],
      })}
    </>
  );
}

export default Teams;
