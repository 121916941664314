// This should be a component that will receive as props the title of a position and a link to a pdf file with the description of the position. It should render a card with the title and a button that will open the pdf file in a new tab.

// The component should render inside the card the thumbnail of the pdf file, the title of the position and a button with the text "View Description" that will open the pdf file in a new tab.import React from 'react';
import React from "react";
import { Document, Page } from "react-pdf";
import { Card, Button } from "react-bootstrap";
import coms from "../../Resources/Pics/recruitment/coms_pic.webp"

const PosDescElement = ({ title, link }) => {
  return (
    <div>
      <Card className="height-of-card">
        <Card.Body>
          <Card.Title>{title}</Card.Title>
          <Card.Img src={coms} alt={coms}/>
          <Button
            href={link}
            target="_blank"
            className="btn-primary-green button-bottom"
          >
            View Description
          </Button>
        </Card.Body>
      </Card>
    </div>
  );
};

export default PosDescElement;
