import Aos from "aos";
import React, { useEffect } from "react";

// image imports
import decorImg from "../../Resources/Pics/aboutus/decorImg.webp";
import development from "../../Resources/Pics/aboutus/Developement.webp";
import unity from "../../Resources/Pics/aboutus/Unity.webp";
import sustainability from "../../Resources/Pics/aboutus/Sustainability.webp";

const AboutUs = () => {
  useEffect(() => {
    Aos.init();
  }, []);

  const duration = 1000;

  return (
    <div>
      <div
        data-aos="fade-left"
        data-aos-duration={duration}
        className="special container simple-section mb-5"
      >
        <div className="row simple-section-header">
          <h1>
            Our <text className="emphasis"> /mission </text>
          </h1>
        </div>
        <div className="row simple-section-body">
          <p>
            At Top Dutch Solar Racing, we revolutionize mobility and develop
            young talents through hard work and intense collaboration to
            contribute to a sustainable world for everyone.
          </p>
        </div>
      </div>
      <div
        data-aos="fade-left"
        data-aos-duration={duration}
        className="special container simple-section mt-5 mb-5"
      >
        <div className="row simple-section-header">
          <h1>
            About <text className="emphasis">/us</text>
          </h1>
        </div>
        <div className="row simple-section-body">
          <p>
            <strong>
              Top Dutch Solar Racing is a multidisciplinary solar racing team
              from Groningen, the Netherlands. What makes the team unique is the
              fact that it is not linked to a technical university. Instead,
              team members study at various educational institutions of
              different levels (MBO, HBO and WO). We believe that everyone has
              unique strengths and knowledge that should be nurtured in order to
              contribute to the success of the team.
            </strong>
          </p>
          <p>
            The backbone of the team is the foundation Solar Racing Groningen,
            which was founded by and still consists of student team members.
            Besides building a solar car, the team also manages its
            communication, acquisition and travel logistics on its own. What
            connects the students is the motivation to build the most efficient
            solar car and to represent the North of the Netherlands during
            various solar challenges. Traditionally, the Bridgestone World Solar
            Challenge is the place to be for all aspiring solar teams, but
            besides that Top Dutch Solar Racing is also participating in the
            iLumen European Solar Challenge and Moroccan Solar Challenge.
          </p>
          <img src={decorImg} alt="" className="img-fluid"></img>
          <p>
            <br></br>
          </p>
          <p>
            The multidisciplinary nature of Top Dutch Solar Racing is also
            represented through the team logo. The O of “Solar Racing” depicts
            two circles with three compounds each. Whereas the outer circle
            represents the collaboration of universities, companies and students
            within the project, the inner circle stands for the
            multidisciplinary approach of the team members. The three different
            shades of green symbolize the three northern provinces Groningen,
            Friesland and Drenthe. Being the only solar team in the North, the
            team sees itself as an ambassador of innovation and technology
            within the sustainable mobility sector for the whole region.
          </p>
        </div>
      </div>

      <div
        data-aos="fade-right"
        data-aos-duration={duration}
        className="container simple-section mb-5"
      >
        <div className="row simple-section-header">
          <h1>
            Our core <text className="emphasis"> /values </text>
          </h1>
        </div>
        <div className="row simple-section-body">
          <div className="col-4 col-md-2 pl-3">
            <img
              src={development}
              className="img-fluid"
              width="120"
              height="40"
              alt="development"
            ></img>
          </div>
          <div className="col-8 col-md-10">
            <h2>Development</h2>
            <p>
              We develop technological innovations for current and future
              generations and cultivate students to open-minded professionals.
              Our strength lies in pushing past our personal limits and
              technological boundaries; we value development.
            </p>
          </div>
        </div>
        <div>
          <br></br>
        </div>
        <div className="row simple-section-body">
          <div className="col-8 col-md-10 pl-3 order-sm-first">
            <h2>Unity</h2>
            <p>
              A chain is only as strong as its weakest link. Our team is at its
              strongest when we collaborate together towards a common goal. We
              lift each other up when we are down. Sharing the same vision and
              values; we are united.
            </p>
          </div>
          <div className="col-4 col-md-2 order-sm-last">
            <img
              src={unity}
              className="img-fluid"
              width="120"
              height="40"
              alt="unity"
            ></img>
          </div>
        </div>
        <div>
          <br></br>
        </div>

        <div className="row simple-section-body">
          <div className="col-4 col-md-2 pl-3">
            <img
              src={sustainability}
              className="img-fluid"
              width="120"
              height="40"
              alt="sustainability"
            ></img>
          </div>
          <div className="col-8 col-md-10">
            <h2>Sustainability</h2>
            <p>
              A sustainable world is the only way forward. The earth is warming
              up and we have now reached a point-of-no-return. Innovating
              technologies that have the potential of reducing emissions is what
              drives us; we drive sustainability.
            </p>
          </div>
        </div>
        <div>
          <br></br>
        </div>
      </div>

      <div
        data-aos="fade-right"
        data-aos-duration={duration}
        className="special container simple-section mb-5"
      >
        <div className="row simple-section-header">
          <h1>
            The <text className="emphasis"> /founders </text>
          </h1>
        </div>
        <div className="row simple-section-body">
          <p>
            Top Dutch Solar Racing was founded in 2017 by four students from
            Hanze University of Applied Sciences: Jeroen Brattinga, Frank Pot,
            Eldert Zeinstra and Vincent Taselaar. They studied Electrical and
            Mechanical Engineering and wanted to use their technical knowledge
            to contribute to sustainable mobility solutions and innovations.
            Together with 22 other highly motivated students, they built the
            solar car Green Lightning. With this solar car Top Dutch Solar
            Racing participated in the Bridgestone World Solar Challenge 2019
            for the first time and finished 4th as best Dutch team. Besides
            that, they received the Excellence in Engineering Award, a prize
            that honours Green Lightning’s high quality of engineering despite
            the fact that it was the first time the students participated in the
            BWSC.
          </p>
        </div>
      </div>
    </div>
  );
};

export default AboutUs;
